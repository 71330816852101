import { Fragment, useRef, useState } from 'react';
import { IonButton, IonInput, IonLabel, IonTitle, useIonModal, IonSelect, IonSelectOption, SelectChangeEventDetail, IonList, IonItem, IonAccordionGroup, IonAccordion, IonNote } from '@ionic/react';
import { useQueryClient, useMutation } from 'react-query';
import { useApiContext } from '../hooks/apiContext';
import { useAuthContext } from '../hooks/authContext';
import { SingleMemberTypes } from './interfaces/Dashboard';
import { capitalizeAllFirstWords, capitalizeFirstLetter } from '../utilities/capitalization';
import randomInt from '../utilities/random-int';
import AnswerQuestions from './AnswerQuestions';
import { AuthContextTypes } from '../AuthProvider';
import { ApiService } from '../services/api-service';

export interface UserDetailsInputTypes {
  fname: string
  lname: string
  email: string
}

export interface UserDetailsSelectTypes {
  relationship: string[]
  industry: string[]
  kids: string[]
}

export interface UserDetailsTypes extends UserDetailsInputTypes, UserDetailsSelectTypes { }

const DetailsOfModalBody: React.FC<{
  onDismiss: () => void,
  userData: SingleMemberTypes,
  app: AuthContextTypes,
  api: ApiService
}> = ({ onDismiss, userData, app, api }) => {


  const formRef = useRef<HTMLFormElement>(null);
  const editDetails = useRef({} as UserDetailsTypes);
  const [emailWarning, setEmailWarning] = useState(true);
  const toggleEmailWarning = () => {
    setEmailWarning(!emailWarning);
  }

  const mutation = useMutation(async () => {
    await api.updateDetails(app.userDetails?.['custom:memberId'], app.userDetails?.email, editDetails.current, app.jwt).then((res: any) => {
      console.log('update details res: ', res);
      return res;
    }).catch((err: string | undefined) => { throw new Error(err) });
  }, {
    onSuccess: () => {
      alert('Edits made! 🥳 We\'ll refresh the page for you now.');
      window.location.reload();
    },
    onError: (e: Error) => {
      console.log('error! ', e.message);
      alert('There was an error trying to update your details. Please try again later.');
    }
  });

  const handleSubmit = () => {
    console.log(app.userDetails);
    const inputs = formRef.current?.querySelectorAll('input');
    if (inputs) {
      const inputsArray = Array.from(inputs);
      inputsArray.forEach(({ name, value }) => {
        console.log('i value: ', value);
        if (['relationship', 'kids', 'industry'].includes(name)) editDetails.current[name as keyof UserDetailsSelectTypes] = [value];
        else editDetails.current[name as keyof UserDetailsInputTypes] = value.trim();
      });
      if (Object.keys(editDetails.current).length > (inputsArray.length)) {
        alert('Please make sure no inputs or selections are blank.');
        return;
      } else {
        console.log('details: ', editDetails.current);
        mutation.mutate();
      }
    }
  }

  return (
    <aside style={{ padding: "2rem" }}>
      <span onClick={onDismiss} style={{ float: "right", cursor: "pointer" }}>X</span><IonTitle>Edit User Details</IonTitle>
      <form ref={formRef} style={{ padding: "2rem" }}>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">First Name:</IonLabel>
            <IonInput className="input-background" type="text" name="fname" placeholder="First Name" value={capitalizeFirstLetter(userData?.user.M.fname.S!)} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Last Name:</IonLabel>
            <IonInput className="input-background" type="text" name="lname" placeholder="Last Name" value={capitalizeFirstLetter(userData?.user.M.lname.S!)} />
          </IonItem>
          <IonItem>
            <IonNote hidden={emailWarning}><span role="img" aria-label="warning">⚠️</span> Changing email is for contact purposes only. Will not change your email as your username to log in.</IonNote>
            <IonLabel position="stacked">Email:</IonLabel>
            <IonInput onFocus={() => toggleEmailWarning()} onBlur={() => toggleEmailWarning()} className="input-background" inputMode="email" type="email" name="email" placeholder="Email" value={userData?.user.M.email.S} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Relationship status:</IonLabel>
            <IonSelect className="select-background" name="relationship" placeholder="Select an option" value={capitalizeFirstLetter(userData?.relationship?.L[0]?.S || 'N/A')}>
              {["Single", "In a relationship", "Engaged", "Married", "Separated", "Divorced", "Widowed"]
                .map(((s: string, index: number) => <IonSelectOption key={`rel-${index}-${randomInt()}`} value={s}>{s}</IonSelectOption>
                ))}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Industry:</IonLabel>
            <IonSelect className="select-background" name="industry" placeholder="Select an option" value={capitalizeAllFirstWords(userData?.industry.L[0].S! ?? 'N/A')}>
              {["Agriculture, Forestry, Fishing, and Hunting",
                "Utilities", "Manufacturing", "Transportation and Warehousing",
                "Software, It", "Broadcasting, Media", "Real Estate, Rental and Leasing",
                "Primary/Secondary (K-12) Education", "Health Care and Social Assistance",
                "Hotel and Food Services", "Legal Services", "Retail", "Construction",
                "Telecommunications", "Information Services and Data Processing",
                "Finance and Insurance", "College, University, and Adult Education",
                "Arts, Entertainment, and Recreation", "Government and Public Administration",
                "Scientific or Technical Services", "Military", "Marketing, Advertising, Sales",
                "Consulting", "Nonprofit", "Events, Tourism", "Other..."]
                .map(((s: string, index: number) => <IonSelectOption key={`ind-${index}-${randomInt()}`} value={s}>{s}</IonSelectOption>
                ))}
            </IonSelect>
          </IonItem>
          <IonAccordionGroup>
            <IonAccordion value="first">
              <IonItem slot="header">
                <IonLabel>Questions for Employers:</IonLabel>
              </IonItem>
              <aside className="ion-padding" slot="content">
                <AnswerQuestions userData={userData} />
              </aside>
            </IonAccordion>
          </IonAccordionGroup>
        </IonList>
        {/* <IonLabel position="stacked">Kids:</IonLabel>
        <IonSelect name="kids" value={queryUserData?.state.data?.kids.L[0]}>
          {["I have kids", "Not yet", "Expecting", "All over 18", "No thanks"]
            .map((s: string, index: number) => <IonSelectOption key={`kid-${index}-${randomInt()}`} value={s}>{s}</IonSelectOption>
          ))}
        </IonSelect> */}
        <aside style={{ textAlign: "center" }}>
          <IonButton onClick={handleSubmit}>Save</IonButton>
          <br />
          <IonButton fill="clear" color="dark" onClick={() => onDismiss()}>Cancel</IonButton>
        </aside>
      </form>
    </aside>
  );
}

const UserDetailsModalBtn: React.FC = () => {

  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData<SingleMemberTypes>('userData');

  const app = useAuthContext();
  const api = useApiContext();

  const handleSelect = (e: CustomEvent<SelectChangeEventDetail>) => {
    switch (e.detail.value) {
      case 'edit':
        present();
        break;
      default:
        console.log('no good value');
        break;
    }
  }

  const handleDismiss = () => {
    dismiss();
  }

  const [present, dismiss] = useIonModal(DetailsOfModalBody, {
    onDismiss: handleDismiss,
    userData,
    app,
    api
  });

  return (
    <Fragment>
      <IonSelect value="default" onIonChange={handleSelect}>
        <IonSelectOption value="default" defaultChecked >{capitalizeAllFirstWords(`${userData?.user.M.fname.S} ${userData?.user.M.lname.S}`)}</IonSelectOption>
        <IonSelectOption value="edit">Edit Profile</IonSelectOption>
      </IonSelect>
    </Fragment>
  );
}

export default UserDetailsModalBtn;