/* Styles */
import '../style/UserProfileModal.css';

import {
  IonButton,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonModal,
  IonNote,
  IonRow,
} from "@ionic/react";
import { useRef } from "react";
import { UserMatch } from "../services/user-matches";
import { getMatchRanking, getMatchRankingColor } from "../utilities/get-match-ranking";
import ProfileInformationAccordion from "./ProfileInformationAccordion";
import HexacoMetrics from "./HexacoMetrics";
import ProfilePersonaData from './ProfilePersonaData';
import Avatar from 'react-avatar';


type Props = UserMatch

function UserProfileModal({ id, user, points, archetype, profile_picture_url, ...rest }: Props) {
  const modal = useRef<HTMLIonModalElement>(null);

  function dismiss() {
    modal.current?.dismiss();
  }

  return (
    <>
      <IonChip id={id.S} color={"dark"} style={{
        marginTop: 9
      }}>
        Show Profile
      </IonChip>
      <IonContent>
        <IonModal ref={modal} trigger={id.S} backdropDismiss={false} className='ion-custom-modal'>
          <div className="ion-modal-container">
            <div className="ion-modal-header">
              <IonButton onClick={dismiss}>Close Profile</IonButton>
            </div>
            <div className="ion-modal-basic-information">
              {profile_picture_url?.S ? <Avatar className='ion-profile-avatar' src={profile_picture_url?.S} fgColor="black" color="#C7B8F5" round size="100" /> :
                <Avatar className='ion-profile-avatar' name={`${user.M.fname.S} ${user.M.lname.S}`} fgColor="black" color="#C7B8F5" round size="100" />}
              <div>
                <h6 className="ion-modal-header">{user.M.fname.S} {user.M.lname.S}'s profile</h6>
                <div>
                  <IonNote>Your Match Score: {points}%
                    <span style={{
                      color: getMatchRankingColor(points),
                      textTransform: "capitalize"
                    }}> ({getMatchRanking(points)} Kindred Match)</span>
                  </IonNote>
                </div>
              </div>
            </div>
            <div>
              <ProfilePersonaData persona={archetype.S} orgcode={user.M.orgcode.L[0].S || 'unknown'} useThirdPerson={true} />
            </div>
            <IonGrid className="ion-margin-top">
              <IonRow>
                <IonCol size="12" sizeMd="6">
                  <HexacoMetrics hexaco={rest.hexaco} />
                </IonCol>
                <IonCol size="12" sizeMd="6" className='ion-margin-top'>
                  <ProfileInformationAccordion
                    city_current={rest.city_current}
                    user={user}
                    education={rest.education}
                    hobbies={rest.hobbies}
                    universities={rest.universities}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonModal>
      </IonContent>
    </>
  );
}

export default UserProfileModal;
