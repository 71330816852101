export const capitalizeFirstLetter = (word: string | undefined) => {
  if(word) return word.charAt(0).toUpperCase() + word.slice(1);
}

export const capitalizeAllFirstWords = (words: string | undefined) => {
  if(words){
    const wordsArray = words.split(' ');
    const returnPhrase = wordsArray.map(w => {
      if(['and', 'or'].includes(w)) return '';
      return `${capitalizeFirstLetter(w)}`;
    }).join(' ').trim();
    return returnPhrase;
  }
}