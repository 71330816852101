import { Fragment } from "react";
import { UserMatch } from "../services/user-matches";
import PercentBar from "./PercentBar";
import randomInt from "../utilities/random-int";
import { ExplanationsTypes } from "./Dashboard";

type Props = Pick<UserMatch, "hexaco">

const explanations: ExplanationsTypes = {
    "honesty": "Measures individual differences in peoples' sincerity, fairness, greed avoidance, and modesty",
    "emotionality": "Described as often being self-conscious and shy, and tending to have trouble controlling urges and delaying gratification",
    "extraversion": "Tends to be manifested in outgoing, talkative, energetic behavior. Opposite is more reflective and reserved behavior",
    "agreeableness": "Agreeable individuals display a tendency to perceive others in a more positive light",
    "conscientiousness": "Implies a desire to do a task well, and to take obligations to others seriously",
    "openness": "Motivated to seek new experiences and to engage in self-examination"
}

function HexacoMetrics({ hexaco }: Props) {
    return (<>
        {Object.entries(hexaco.M).sort().filter((([k, v]) => k !== 'latestAssessment')).map(([key, value], index) => {
            if (key === 'neuroticism') key = 'emotionality';
            return (
                <Fragment key={`${index}-${randomInt()}`}>
                    <PercentBar title={key} hexNumber={value.N} denominator={30} />
                    <p style={{
                        marginTop: "8px",
                        marginBottom: "6px"
                    }}>{explanations[`${key}` as keyof ExplanationsTypes]}</p>
                </Fragment>
            );
        })}
    </>)
}

export default HexacoMetrics;