import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import './style/App.css';

import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/fonts.css';

import AuthProvider from './AuthProvider';
// import Menu from './components/Menu';
import ChatProvider from './ChatProvider';
import { OrganizationProvider } from './OrganizationContext';

setupIonicReact();

const App: React.FC = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <OrganizationProvider>
        <IonReactRouter>
          <AuthProvider>
            <ChatProvider>
              <IonApp>
                <Route exact path="/">
                  <Redirect to="/Login" />
                </Route>
                <IonRouterOutlet id="main">
                  <Route exact path="/:name">
                    <Home />
                  </Route>
                </IonRouterOutlet>
              </IonApp>
            </ChatProvider>
          </AuthProvider>
        </IonReactRouter>
      </OrganizationProvider>

    </QueryClientProvider>
  );
}

export default App;
