import { IonCol, IonGrid, IonItem, IonLabel, IonLoading, IonRow } from '@ionic/react';
import { useLayoutEffect } from 'react';
import { useHistory } from 'react-router';
import { useAuthContext } from '../hooks/authContext';
import { SingleMemberTypes } from './interfaces/Dashboard';
import UserConnections from './UserConnections';

interface ConnectionsTypes {
  data: SingleMemberTypes | undefined
}

const Connections = ({ data }: ConnectionsTypes) => {
  const history = useHistory();
  const auth = useAuthContext();

  useLayoutEffect(() => {
    if (!auth.auth) history.push('/');
  }, []);

  if (data) return (
    <IonGrid>
      <IonRow>
        <IonCol sizeLg="6" sizeMd="6" sizeSm="12">
          <UserConnections id={data.id?.S} name={data.user.M.fname.S} connectionIds={data.connectionIds} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  else return <IonLoading isOpen />;
}

export default Connections;