export const isDatePastLimit = (date: string, limit: number) => {
  // console.log('date: ', date, typeof date);
  // console.log('limit: ', limit, typeof limit);
  const date1 = new Date(date.toUpperCase());
  const date2 = Date.now();
  const diffTime = Math.abs(date2 - date1.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // console.log('diffDays: ', diffDays);
  if (diffDays > limit) {
      // console.log("The datetime has surpassed the limit.");
      return true;
  } else {
      // console.log("The datetime has not surpassed the limit.");
      return false;
  }
}