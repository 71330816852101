import { IonAccordion, IonAccordionGroup, IonBadge, IonItem, IonLabel } from "@ionic/react";
import MagicQuestionActivities from "../data/activities.json";
import { MagicQuestionsActivity } from "../services/magic-questions/activities";
import ReactPlayer from 'react-player'

function MagicQuestionsDropdown() {

	const data: MagicQuestionsActivity[] = MagicQuestionActivities;

	return (
		<IonAccordionGroup>
			{data.map((activity, index) => (
				<IonAccordion key={index} value={index.toString()}>
					<IonItem slot="header">
						<IonLabel style={{
							fontWeight: "bold"
						}}>{activity.title}</IonLabel>
					</IonItem>
					<div className="ion-padding" slot="content">
						<div>
							<div style={{
								display: "flex",
								alignItems: "center",
								gap: 4
							}}>
								<p>Time Required: </p>
								<IonBadge>{activity.time}</IonBadge>
							</div>
							<p><b>Overview</b>: {activity.overview}</p>
							{activity.reason && <p><b>Reason</b>: {activity.reason}</p>}
							<p><b>Directions</b>:</p>
							<ul>
								{activity.directions.map((direction, index) => (
									<li key={`ActivityIndex-${index}`}>
										<p>{direction.text}</p>
										{(direction.includesVideo && !direction.videoUrl) && <IonBadge>Coming Soon</IonBadge>}
										{(direction.videoUrl && <ReactPlayer light={direction?.thumbnailUrl} url={direction.videoUrl} controls />)}
										<ul>
											{
												direction?.sublist?.map((item, key) =>
													<li key={`ActivityDirection#${key}`}>
														<p >{item.text}</p>
														<ol>
															{
																item?.sublist?.map((item, key) => <li key={`AcitivySublistItem#${key}`}>
																	<p>{item.text}</p>
																</li>)
															}
														</ol>
													</li>
												)
											}
										</ul>
									</li>
								))}
							</ul>
							{
								activity.sections?.map((item, key) => (
									<div key={`ActivitySection#${key}`}>
										<IonItem slot="header" color="light">
											<IonLabel>{item.title}</IonLabel>
										</IonItem>
										<aside className="ion-padding" slot="content">
											<p>{item.subtitle}</p>
											<ol>
												{item.items?.map((item, key) => (
													<li key={`ActivitySubItem#${key}`}>
														<p>{item.text}</p>
														<ul>
															{item?.sublist?.map((subItem, key) => <li key={key}>{subItem.text}</li>)}
														</ul>
													</li>
												))}
											</ol>
										</aside>
									</div>
								))
							}
						</div>
					</div>
				</IonAccordion>
			))}
		</IonAccordionGroup>
	)
}

export default MagicQuestionsDropdown;