import { Fragment, useState } from 'react';
import { IonItem, IonList, IonSpinner, useIonModal } from '@ionic/react';
import MessagingModalBody from './MessagingModal';
import { ChatListTypes, ChatTypes } from '../interfaces/Messaging';
import { capitalizeAllFirstWords } from '../../utilities/capitalization';
import { useApiContext } from '../../hooks/apiContext';
import { useAuthContext } from '../../hooks/authContext';
import Avatar from 'react-avatar';
import { anyUnreadMessages, updateMessageCount } from '../../utilities/updateMessageCount';
import { useChatContext } from '../../hooks/chatContext';
import randomInt from '../../utilities/random-int';


const ChatList = ({ chatIds }: ChatListTypes) => {

  const api = useApiContext();
  const auth = useAuthContext();
  const chat = useChatContext();

  const [modalDetails, setModalDetails] = useState<ChatTypes | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const handleDismiss = () => {
    chat.currentOpenChatId.current = undefined;
    dismiss();
  }
  const [present, dismiss] = useIonModal(MessagingModalBody, {
    onDismiss: handleDismiss,
    messageDetails: modalDetails
  });

  const handleMessageChoice = async (chatDetails: ChatTypes) => {
    setLoading(true);
    try {
      // get userid in chat details (that's the person that received the message)
      const recipientDetails = await api.getRecipient(chatDetails.userId?.S, auth.jwt);

      // set messages to 0 (db, then state)
      await api.resetMessageNumber(auth.userDetails?.['custom:memberId'], chatDetails.chatId?.S, auth.jwt);
      updateMessageCount(0, chat.messages, chatDetails.chatId?.S!, chat.updateMessages)
      if (!anyUnreadMessages(chat.messages)) chat.setMessageCounter && chat.setMessageCounter(0)
      // then get that user's details and add to chatDetails (will have to use an extended interface)
      const fullDetails = { ...{ ...chatDetails, ...recipientDetails }, newChat: false };
      setModalDetails(fullDetails);
      present({ backdropDismiss: false });
      setLoading(false);
    } catch (e) {
      alert('Something went wrong. Reloading your browser.');
      window.location.reload();
    }
  }

  if (loading) return <IonSpinner name="crescent" />

  return (
    <aside>
      <IonList lines="inset">
        {chatIds.map(({ M }) => {
          return (
            <Fragment key={`${M.userId?.S}-${randomInt()}`}>
              <IonItem button onClick={() => handleMessageChoice(M)}>
                <Avatar name={M.name.S} size="25" round /><aside style={{ marginLeft: "0.3rem" }}><strong>{capitalizeAllFirstWords(M.name.S)}</strong>&nbsp;{M.messageCount.N > 0 && <Avatar size="20" round value={M.messageCount.N.toString()} />}</aside>
              </IonItem>
            </Fragment>
          )
        })}
      </IonList>
    </aside>
  );
}

export default ChatList;