import { IonButton, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonList, IonNote, IonRow, useIonAlert } from '@ionic/react';
import { KeyboardEvent, MutableRefObject, useEffect, useLayoutEffect, useReducer, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { useAuthContext } from '../hooks/authContext';
import { useCognitoContext } from '../hooks/cognitoContext';
import { AuthAttributeTypes, AuthResultTypes, UnAuthCreditsTypes } from '../services/cognito-service';
import '../style/Login.css';
import { RegisterTypes } from './interfaces/register';
import '../style/Register.css';

const useForceUpdate = () => {
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  return forceUpdate;
}

const Register = () => {
  const cognito = useCognitoContext();
  const userAuth = useAuthContext();
  const history = useHistory();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const isButtonDisabled = useRef(false);
  const forceUpdate = useForceUpdate();
  const unauthCreds = useRef<UnAuthCreditsTypes | undefined>();
  const registerValidator: MutableRefObject<SimpleReactValidator> = useRef(new SimpleReactValidator(
    {
      validators: {
        internationalPhone: {  // name the rule
          message: 'The :attribute field must be a valid phone number (e.g. 4145557777)',
          rule: (val: string, params: string[]) => { // looks like it needs to allow the plus and an extra set of numbers...
            return registerValidator.current.helpers.testRegex(val, /^(\s*\d\s*){7,15}$/) && params.indexOf(val) === -1
          }
        }
      }
    }
  ));
  const registerValues = useRef<RegisterTypes>({
    firstName: queryParameters.get("fname") as string ?? '',
    lastName: queryParameters.get("lname") as string ?? '',
    countryCode: '',
    phone: '',
    email: queryParameters.get("email") as string ?? '',
    reEmail: queryParameters.get("email") as string ?? '',
    random: ''
  });
  const inputSectionRef = useRef<HTMLIonInputElement>(null);
  const [present] = useIonAlert();
  const authAttribtesRef = useRef<AuthAttributeTypes | AuthResultTypes>({} as AuthAttributeTypes);
  const randomQuestionsArray = [
    'Most skunks are both black and which other color?',
    'What color is a banana?',
    'What is the name of the caffeinated, dark brown drink that many people have in the morning?',
  ];

  const randomQuestion = useRef<string>(randomQuestionsArray[1]);
  const startTime = useRef<Date | undefined>(undefined);

  // useEffect(() => {
  // }, [refreshToggle]);

  useLayoutEffect(() => {
    randomQuestion.current = randomQuestionsArray[Math.round(Math.random() * (2 - 0) + 0)];
    // This is where you check for time (human v bot). Use a ref.
    startTime.current = new Date();
  }, []);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') handleRegister();
  }

  const singleValidationCheck = (f: string, tag: string, val: string, validatorCheck: string) => {
    registerValues.current[`${tag}` as keyof RegisterTypes] = val;
    if (!registerValidator.current.fieldValid(f)) {
      registerValidator.current.showMessageFor(f);
      forceUpdate();
    } else if (!registerValidator.current.check(val, validatorCheck)) {
      registerValidator.current.showMessageFor(f);
      forceUpdate();
    }
    else {
      registerValidator.current.hideMessageFor(f);
      forceUpdate();
    }
  }

  const handleRegister = async () => {
    if (isButtonDisabled.current) return;
    isButtonDisabled.current = true;

    const difference = startTime.current?.getSeconds()! - new Date().getSeconds();
    if (Math.abs(difference) < 2) {
      console.log('bot alert');
      return;
    }

    if (!registerValidator.current.allValid()) {
      registerValidator.current.showMessages();
      isButtonDisabled.current = false;
      return;
    }

    const inputs = inputSectionRef.current?.querySelectorAll('input');

    if (inputs) {
      // set inputs here
      const inputsArray = Array.from(inputs);

      // Checks to see if bot placed info into input
      if (inputsArray.find(({ name }) => name === 'honeypot')?.value) { alert('I see we have a robot in our hands. We can\'t let you through.'); return; }

      // In case user uses autocomplete
      const fNameInput = inputsArray.find(({ name }) => name === 'first name');
      registerValues.current.firstName = (fNameInput?.value as RegisterTypes['firstName']).trim().toLowerCase();
      const lNameInput = inputsArray.find(({ name }) => name === 'last name');
      registerValues.current.lastName = (lNameInput?.value as RegisterTypes['lastName']).trim().toLowerCase();
      const countryCodeInput = inputsArray.find(({ name }) => name === 'countryCode');
      registerValues.current.countryCode = (countryCodeInput?.value as RegisterTypes['countryCode']).trim().toLowerCase() || "1";
      const phoneInput = inputsArray.find(({ name }) => name === 'phone');
      registerValues.current.phone = `+${registerValues.current.countryCode}${(phoneInput?.value as RegisterTypes['phone']).trim().toLowerCase().replace(/-/g, '')}`;
      const emailInput = inputsArray.find(({ name }) => name === 'email');
      registerValues.current.email = (emailInput?.value as RegisterTypes['email']).trim().toLowerCase();
      const reEmailInput = inputsArray.find(({ name }) => name === 'reenter-email');
      registerValues.current.reEmail = (reEmailInput?.value as RegisterTypes['reEmail']).trim().toLowerCase();
      const randomInput = inputsArray.find(({ name }) => name === 'random');
      registerValues.current.random = (randomInput?.value as RegisterTypes['random']).trim().toLowerCase();

      if (emailInput?.value !== reEmailInput?.value) {
        alert('The email values don\'t match. Please make the necessary changes.');
        isButtonDisabled.current = false;
        return;
      }
    }

    try {
      // get creds
      unauthCreds.current = await cognito.getCredentials(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_UNAUTH_GUEST! : '/v1/get-unauth-creds').then(res => res).catch(err => { throw err });
      // then send data over to register
      const message = await cognito.register(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_REGISTER! : '/v1/register', registerValues.current, JSON.parse(unauthCreds.current?.body!));
      alert(message);
      if (!message.includes('It looks like you')) {
        history.push('/Login');
        return;
      }
      // console.log("results: ", registerValues.current);
      // setIsButtonDisabled(false);
    } catch (e) {
      console.error('registration error: ', e);
      alert(`There was an error: ${e}`);
      isButtonDisabled.current = false;
    }

  }
  return (
    <section ref={inputSectionRef} className="satoshi login-container">
      <h3>Please enter your info to register for a Rivet User Profile <span role="img" aria-label="happy-face">😊</span></h3>
      <IonNote>You must have completed the Rivet assessment before registering</IonNote>
      <hr />
      <form>
        <IonList mode="ios">
          <IonItem mode="ios">
            <IonLabel mode="ios" position="stacked">First Name</IonLabel>
            <IonInput className="input-background" type="text" required placeholder="First Name" name="first name" value={registerValues.current.firstName} onBlur={(e) => singleValidationCheck('first name', 'firstName', e.target.value as string, 'required|alpha|min:2,string')} />
            {registerValidator.current.message('first name', registerValues.current.firstName, 'required|alpha_space|min:2,string', { className: 'text-danger' })}
          </IonItem>
          <IonItem mode="ios">
            <IonLabel mode="ios" position="stacked">Last Name</IonLabel>
            <IonInput className="input-background" type="text" required placeholder="Last Name" name="last name" value={registerValues.current.lastName} onBlur={(e) => singleValidationCheck('last name', 'lastName', e.target.value as string, 'required|alpha|min:2,string')} />
            {registerValidator.current.message('last name', registerValues.current.lastName, 'required|alpha_space|min:2,string', { className: 'text-danger' })}
          </IonItem>
          <IonItem mode="ios">
            <IonLabel mode="ios" position="stacked">Phone</IonLabel>
            <IonGrid style={{ margin: "0" }}>
              <IonRow>
                <IonCol sizeXl="3" sizeLg="3" sizeSm="4" sizeXs="4">
                  <IonInput className="input-before phone-input" type="text" required defaultValue="1" placeholder="1" name="countryCode" />
                </IonCol>
                <IonCol sizeXl="9" sizeLg="9" sizeSm="8" sizeXs="8">
                  <IonInput className="phone-input" type="text" required placeholder="e.g. 4145557777" name="phone" onBlur={(e) => singleValidationCheck('phone', 'phone', e.target.value as string, 'required|internationalPhone')} />
                </IonCol>
              </IonRow>
            </IonGrid>
            {registerValidator.current.message('phone', registerValues.current.phone, 'required|internationalPhone', { className: 'text-danger' })}
          </IonItem>
          <IonItem mode="ios">
            <IonLabel mode="ios" position="stacked">Email</IonLabel>
            <IonInput className="input-background" type="email" required placeholder="Email" name="email" value={registerValues.current.email} onBlur={(e) => singleValidationCheck('email', 'email', e.target.value as string, 'required|email')} />
            {registerValidator.current.message('email', registerValues.current.email, 'required|email', { className: 'text-danger' })}
          </IonItem>
          <IonItem mode="ios">
            <IonLabel mode="ios" position="stacked">Confirm Email</IonLabel>
            <IonInput className="input-background" type="email" required placeholder="Email" name="reenter-email" value={registerValues.current.reEmail} onBlur={(e) => singleValidationCheck('reenter-email', 'reEmail', e.target.value as string, 'required|email')} />
            {registerValidator.current.message('reenter-email', registerValues.current.reEmail, 'required|email', { className: 'text-danger' })}
          </IonItem>
          <IonItem mode="ios">
            <IonLabel mode="ios" position="stacked" className="ion-text-wrap"><strong>Captcha:</strong> {randomQuestion.current}</IonLabel>
            <IonInput className="input-background" type="text" required placeholder="One word answer..." name="random" onBlur={(e) => singleValidationCheck('random', 'random', e.target.value as string, 'required|alpha')} />
            {registerValidator.current.message('random', registerValues.current.random, 'required|alpha', { className: 'text-danger' })}
          </IonItem>
          <IonItem className="hidden_honeypot" >
            <IonInput type="text" placeholder="input here..." name="honeypot" />
          </IonItem>
        </IonList>
      </form>
      <IonButton style={{ marginTop: '2rem', marginLeft: '1rem' }} disabled={isButtonDisabled.current} mode="ios" onKeyUp={handleKeyPress} onClick={handleRegister}>{isButtonDisabled.current ? 'Connecting' : 'Register'}</IonButton>
      <h3><span role="img" aria-label="warning">⚠️</span> You will be sent a temporary password, which you can use to finish signing up! <span role="img" aria-label="warning">⚠️</span></h3>
      {/* <Link style={{marginLeft: '1.5rem', display: 'block', marginTop: '1rem'}} to="/NewPassword">Forgot Password?</Link> */}
    </section>
  );
}

export default Register;