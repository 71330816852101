/* eslint-disable react-hooks/exhaustive-deps */
import '../style/OrgCodeSelect.css';

import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useContext, useEffect } from 'react';
import { Organization, OrganizationContext } from '../OrganizationContext';
import randomInt from '../utilities/random-int';

interface OrgCodeSelectTypes {
  organizations: Organization[]
  setOrgCode: (value: React.SetStateAction<string | undefined>) => void
}

const OrgCodeSelect = ({ organizations, setOrgCode }: OrgCodeSelectTypes) => {
  const { setSelectedOrg, setList } = useContext(OrganizationContext);

  const setOrgCodeDetails = (value: String) => {
    const selectedOrg = organizations?.find(code => code.orgcode.S === value);
    if (selectedOrg) {
      setSelectedOrg(selectedOrg)
    }
    if (organizations) {
      setList(organizations)
    }
    setOrgCode(value as string);
  }

  useEffect(() => {
    setList(organizations)
    if (organizations.length === 1) setSelectedOrg(organizations[0])
  }, [])

  return (
    <>
      <h3>Looks like you may belong to more than one organization.</h3>
      <IonItem className="glowing-border">
        <IonLabel position="stacked"><h1>Choose organization:</h1></IonLabel>
        <br />
        <IonSelect mode="ios" placeholder="Choose an organization..." onIonChange={(e) => setOrgCodeDetails(e.detail.value)}>
          {organizations!.map((o, index) => {
            return <IonSelectOption key={`${randomInt()}-${index}`} value={o.orgcode.S}>{o.orgname.S?.toUpperCase()}</IonSelectOption>;
          })}
        </IonSelect>
      </IonItem>
    </>
  )
}

export default OrgCodeSelect;