import { IonCol, IonGrid, IonRow, IonTitle, useIonModal } from '@ionic/react';
import { useEffect, useLayoutEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useChatContext } from '../../hooks/chatContext';
import { MessageMemberTypes } from '../interfaces/Connections';
import { ChatTypes } from '../interfaces/Messaging';
import ChatList from './ChatList';
import MessagingModalBody from './MessagingModal';
import { useAuthContext } from '../../hooks/authContext';
import { useHistory } from 'react-router';

const Messaging = () => {

  // if queryClient.getQueryData 'message-user' has id, then open up the modal
  const queryClient = useQueryClient();
  const cachedDetails = queryClient.getQueryData<MessageMemberTypes | ChatTypes>('message-user');

  const handleDismiss = () => {
    // clear data on dismiss
    queryClient.setQueryData<MessageMemberTypes>('message-user', () => ({} as MessageMemberTypes));
    dismiss();
  }
  const [present, dismiss] = useIonModal(MessagingModalBody, {
    onDismiss: handleDismiss,
    messageDetails: { ...cachedDetails }
  });

  const chat = useChatContext();
  const auth = useAuthContext();
  const history = useHistory();

  useLayoutEffect(() => {
    if (!auth.auth) history.push('/');
  }, []);

  useEffect(() => {
    if ((cachedDetails as MessageMemberTypes)?.newChat === true) present({
      backdropDismiss: false,
    });
    else if ((cachedDetails as ChatTypes)?.newChat === false) present({
      backdropDismiss: false,
    });
    chat.setMessageCounter && chat.setMessageCounter(0);
    return () => {
      handleDismiss();
    }
  }, []);

  return (
    <IonGrid className="satoshi">
      <IonRow>
        <IonCol>
          <IonTitle>Messages</IonTitle>
        </IonCol>
      </IonRow>
      <IonRow className="ion-margin-top">
        <IonCol sizeXl="6" sizeSm="12" sizeXs="12">
          {chat.messages.length === 0 &&
            <aside style={{ padding: "0 2rem" }}>No messages yet. If you want to start a conversation you can do so by going to <strong>Connections</strong> and clicking on someone you'd like to connect with.</aside>
          }
          <ChatList chatIds={chat.messages} />
        </IonCol>
        <IonCol sizeXl="6" sizeSm="12" sizeXs="12">

        </IonCol>
      </IonRow>
    </IonGrid>
  );
}

export default Messaging