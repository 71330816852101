import * as AWSCognito from 'amazon-cognito-identity-js';
import { createContext } from 'react';
import { AwsClient, AwsV4Signer } from 'aws4fetch';

interface RequiredAttributeTypes {
  family_name: string
  given_name: string
  'custom:organization': string
}

export interface VerificationTypes {
  email: string | number | null | undefined,
  verificationCode: string | number | null | undefined,
  newPassword: string | number | null | undefined
}

export interface AuthAttributeTypes {
  userAttributes: RequiredAttributeTypes
  authDetails: AWSCognito.AuthenticationDetails
  cognitoUser: AWSCognito.CognitoUser
}

export interface IdCredentials {
  AccessKeyId: string
  Expiration: string
  SecretKey: string
  SessionToken: string
}

interface HttpHeaders {
  [key: string]: string;
}

export interface UnAuthCreditsTypes {
  body: string | undefined
}

export interface AuthResultTypes {
  authed: boolean
  jwt: string
  mfaOn: boolean
}

class CognitoService {

  _headers: HttpHeaders;
  _creds: UnAuthCreditsTypes | undefined;

  _POOL_DATA: AWSCognito.ICognitoUserPoolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID ?? 'Pass',
    ClientId: process.env.REACT_APP_CLIENT_ID ?? 'Pass'
  };  
  _userPool: AWSCognito.CognitoUserPool;

  constructor() {
    this._userPool = new AWSCognito.CognitoUserPool(this._POOL_DATA);
    this._headers = { 'Content-Type': 'application/json' };
    if(process.env.NODE_ENV !== 'production') this._headers['X-API-KEY'] = process.env.REACT_APP_API_KEY!;
  }

  getCredentials = async (url = '') => {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: this._headers,
      });
      this._creds = await response.json();
      return this._creds;
    } catch (e) {
      console.log('credentials error: ', e);
    }
  }

  register = async (originalUrl = '', data = {}, token: IdCredentials) => {
    try {
      const signer = new AwsV4Signer({
        url: (process.env.NODE_ENV !== 'production' ? originalUrl : `${process.env.REACT_APP_HOST_NAME}${originalUrl}`),
        method: 'POST',
        accessKeyId: token?.AccessKeyId!,     // required, akin to AWS_ACCESS_KEY_ID
        secretAccessKey: token?.SecretKey!, // required, akin to AWS_SECRET_ACCESS_KEY
        sessionToken: token?.SessionToken,    // akin to AWS_SESSION_TOKEN if using temp credentials
        service: 'execute-api',         // AWS service, by default parsed at fetch time
        region: 'us-east-2',
        appendSessionToken: true,
        body: JSON.stringify(data)
      });
  
      const { method, url, headers, body } = await signer.sign();
      const headersArray = Array.from(headers.entries());
      this._headers[`${headersArray[0][0]}`] = headersArray[0][1];
      this._headers[`${headersArray[1][0]}`] = headersArray[1][1];
      this._headers['X-Amz-Security-Token'] = token.SessionToken!;
      const response = await fetch(originalUrl, {
        method,
        body,
        headers: this._headers
      });
      if(response.status >= 500) throw await response.json();
      return await response.json();
      
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  authenticate = (email: string, password: string): Promise<AuthResultTypes | AuthAttributeTypes> => {
    return new Promise((resolve, reject) => {

      const authDetails = new AWSCognito.AuthenticationDetails({
        Username: email,
        Password: password
      });

      const cognitoUser = new AWSCognito.CognitoUser({
        Username: email,
        Pool: this._userPool
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (res) => {
          resolve({authed: true, jwt: res.getIdToken().getJwtToken(), mfaOn: false});
        },
        onFailure: () => {
          reject(false);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          // the api doesn't accept this field back
          delete userAttributes.email_verified;
          // resolve({userAttributes: {given_name: userAttributes.given_name, family_name: userAttributes.family_name, 'custom:organization': userAttributes.organization} as RequiredAttributeTypes, authDetails: authDetails, cognitoUser: cognitoUser});
          resolve({userAttributes, authDetails: authDetails, cognitoUser: cognitoUser, mfaOn: false});
        },
        mfaRequired: (userAttributes: any) => {
          resolve({userAttributes, authDetails: authDetails, cognitoUser: cognitoUser, mfaOn: true});
        }
      });
    });
  }

  completePasswordChallenge = (newPw: string, authAttributes: AuthAttributeTypes) => {
    return new Promise((resolve, reject) => {
      authAttributes.cognitoUser.completeNewPasswordChallenge(newPw, {}, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (error) => {
          console.error('pass challenge fail: ', error);
          reject(error);
        }
      });
    })
  }

  completeMfaChallenge = (code: string, authAttributes: AuthAttributeTypes) => {
    return new Promise((resolve, reject) => {
      authAttributes.cognitoUser.sendMFACode(code, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (error) => {
          console.error('MFA challenge fail: ', error);
          reject(error);
        }
      });

    })
  }

  isUserAuthenticated = () => {
    return new Promise<AuthResultTypes | boolean>((resolve, reject) => {
      const cognitoUser = this._userPool.getCurrentUser();
      if(!cognitoUser) return reject(false);
      cognitoUser.getSession((err: Error | null, session: AWSCognito.CognitoUserSession) => {
        // console.log('stashed jwt token: ', session.getIdToken().getJwtToken());
        if(err) return reject(false);
        resolve({authed: true, jwt: session.getIdToken().getJwtToken(), mfaOn: false});
      });
    });
  }

  getUserAttrs = (): Promise<AWSCognito.CognitoUserAttribute[] | undefined> => {
    return new Promise((resolve, reject) => {
      const cognitoUser = this._userPool.getCurrentUser();
      if(!cognitoUser) return reject(false);
      // getSession must be called to authenticate user before calling getUserAttributes
      cognitoUser?.getSession((err: Error | null, session: AWSCognito.CognitoUserSession | null) => {
        if(err) return reject(err);
        console.log(`session validity: ${session?.isValid()}`);
    
        cognitoUser.getUserAttributes((err, attrs) => {
          if(err) return reject(err);
          resolve(attrs);
        });
      });
    });
  }

  getUsername = () => {
    const cognitoUser = this._userPool.getCurrentUser()
    return cognitoUser?.getUsername();
  }

  getUserData = () => {
    return new Promise((resolve, reject) => {
      const cognitoUser = this._userPool.getCurrentUser();
      // getSession must be called to authenticate user before calling getUserAttributes
      cognitoUser?.getSession((err: Error | null, session: AWSCognito.CognitoUserSession | null) => {
        cognitoUser?.getUserData((err, data) => {
          if (err) {
            alert(err.message || JSON.stringify(err));
            reject(err);
          }
          console.log('User data for user ', data);
          resolve(data);
        });
      });
    })
  }

  logout = () => {
    const cognitoUser = this._userPool.getCurrentUser();
    cognitoUser?.signOut();
  }

  // FORGOT PASSWORD FLOW
  forgotPassword = async (originalUrl = '', email: string, token: IdCredentials) => {
    // console.log('test hostname: ', `${process.env.REACT_APP_HOST_NAME}${originalUrl}`);
    try {
      const signer = new AwsV4Signer({
        url: (process.env.NODE_ENV !== 'production' ? `${originalUrl}?action=new-password` : `${process.env.REACT_APP_HOST_NAME}${originalUrl}?action=new-password`),
        method: 'POST',
        accessKeyId: token?.AccessKeyId!,     // required, akin to AWS_ACCESS_KEY_ID
        secretAccessKey: token?.SecretKey!, // required, akin to AWS_SECRET_ACCESS_KEY
        sessionToken: token?.SessionToken,    // akin to AWS_SESSION_TOKEN if using temp credentials
        service: 'execute-api',         // AWS service, by default parsed at fetch time
        region: 'us-east-2',
        appendSessionToken: true,
        body: JSON.stringify({email})
      });
  
      const { method, url, headers, body } = await signer.sign();
      const headersArray = Array.from(headers.entries());
      this._headers[`${headersArray[0][0]}`] = headersArray[0][1];
      this._headers[`${headersArray[1][0]}`] = headersArray[1][1];
      this._headers['X-Amz-Security-Token'] = token.SessionToken!;
      const response = await fetch(`${originalUrl}?action=new-password`, {
        method,
        body,
        headers: this._headers
      });
      if(response.status >= 500) throw await response.json();
      return await response.json();
      
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  confirmPassword = ({email, verificationCode, newPassword}: VerificationTypes) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new AWSCognito.CognitoUser({
        Username: email as string,
        Pool: this._userPool
      });

      cognitoUser.confirmPassword(verificationCode as string, newPassword as string, {
        onSuccess() {
          resolve(true);
        },
        onFailure(err) {
          console.error('failed confirming pw: ', err);
          reject(err);
        }
      })
    });
  }
  // END FORGOT PASSWORD FLOW

}

const csInstance = new CognitoService();
const CognitoContext = createContext(csInstance);

export default CognitoContext;