import { IonButton, IonLabel, IonRange, IonTextarea } from '@ionic/react';
import { Fragment, MutableRefObject, useRef } from 'react';
import { ConnectionAnswersTypes } from './ConnectionQuestions';

interface ConnectionQModalTypes {
  onDismiss: (data: any, role: string) => void
  answersRef: MutableRefObject<ConnectionAnswersTypes>
  meetagain: MutableRefObject<string>
  askRating: MutableRefObject<string>
}

const ConnectionQModal = ({ onDismiss, meetagain, askRating, answersRef }: ConnectionQModalTypes) => {

  const asideRef = useRef<HTMLIonTextareaElement | HTMLIonRangeElement>(null);

  const handleSubmit = async () => {
    try {
      const textAreaValues = asideRef.current?.querySelectorAll('textarea');
      const sliderValues = asideRef.current?.querySelectorAll('ion-range');
      const sliderArray = Array.from(sliderValues as NodeListOf<HTMLIonRangeElement>);
      const slider = sliderArray.find(s => s.name === 'rating');
      if (textAreaValues) {
        const textAreaArray = Array.from(textAreaValues);
        const payload = textAreaArray.reduce((p, v) => {
          return { ...p, [`${v.name}`]: v.value };
        }, { ...answersRef.current, rating: slider?.value as number });
        console.log('cqm payload: ', payload);
        answersRef.current = payload;
        onDismiss(payload, 'submit');
      }
    } catch (error) {
      console.error('submit error: ', error);
      alert('Unfortunately, there was an error. Please try again later. Thank you!');
    }
  }

  return (
    <section style={{ padding: "2rem" }}>
      <aside style={{ textAlign: "center", backgroundColor: "white", padding: "1rem" }}>
        <img style={{ maxWidth: "16rem", width: "85%" }} alt="Rivet Connection" src="https://lm-resources.s3.us-east-2.amazonaws.com/rivet-banner-emails.png" />
      </aside>
      <h1>Thank you for helping us make Rivet better for you!</h1>
      <hr style={{ marginBottom: "2rem" }} />
      <aside ref={asideRef}>
        {meetagain.current === '0' &&
          <Fragment>
            <IonLabel>Can you tell us why you will no longer be meeting? (<em>Optional</em>)</IonLabel>
            <IonTextarea style={{ border: "1px solid black" }} name='notagain' placeholder='Type here...'></IonTextarea>
          </Fragment>
        }
        {
          [askRating.current].includes('1') &&
          <aside style={{ textAlign: 'start' }} className="container">
            <IonLabel>How would you rate your interaction? (<em>Optional</em>)</IonLabel>
            <aside style={{ width: '16rem' }}>
              <IonRange mode="ios" name='rating' value={3} snaps pin min={1} max={5} step={1} >
              </IonRange>
              <span style={{ float: 'left' }}>&#60;&#60;&#60; Poor</span>
              <span style={{ float: 'right' }}>Great &#62;&#62;&#62;</span>
            </aside>
          </aside>
        }
        <aside style={{ marginTop: "4rem", marginBottom: "2rem" }}>
          <IonLabel>Anything else you want to share with us? (<em>Optional</em>)</IonLabel>
          <IonTextarea clearOnEdit style={{ border: "1px solid black" }} inputMode='text' maxlength={500} name='feedback' placeholder='Type here...'></IonTextarea>
        </aside>
      </aside>
      <IonButton className="submit-button" onClick={handleSubmit}>Submit</IonButton>
      <IonButton fill="clear" color="dark" onClick={() => onDismiss('', 'cancel')}>Cancel</IonButton>
    </section>
  )
}

export default ConnectionQModal;