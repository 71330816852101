import { useState, FC } from 'react';
import { IonItem, IonLabel } from '@ionic/react';
import '../style/ConnProfileTiles.css';
import MeetingAssistant from './MeetingAssistant';
import Activities from './Activities';

interface ConnProfileTilesProps {
  showMeetingPrompt: boolean;
  paragraph: React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>
  connectionQuestionsEl?: JSX.Element
  oc: string | undefined
  archetype: string | undefined
  dateConnected: string
}

const ConnProfileTiles: FC<ConnProfileTilesProps> = ({ showMeetingPrompt, paragraph, connectionQuestionsEl, oc, archetype, dateConnected }) => {

  const [expanded, setExpanded] = useState<number | null>(null);

  const handleAccordionClick = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <>
      <IonItem button onClick={() => handleAccordionClick(0)} detail>
        <IonLabel>About Your Connection</IonLabel>
      </IonItem>
      <div className={`accordion-content ${expanded === 0 ? 'open' : ''}`}>
        <aside className="ion-padding row-container" style={{textAlign: "start", whiteSpace: "pre-line"}} slot="content">
          {paragraph}
          <MeetingAssistant orgcode={oc} persona={archetype} dateConnected={dateConnected} />
        </aside>
      </div>
      {showMeetingPrompt && (
        <>
          <IonItem button onClick={() => handleAccordionClick(2)} detail>
            <IonLabel>Conversation Prompts</IonLabel>
          </IonItem>
          <div className={`accordion-content ${expanded === 2 ? 'open' : ''}`} style={{textAlign: "start", whiteSpace: "pre-line", height: "fit-content"}}>
            <Activities orgcode={oc} persona={archetype} dateConnected={dateConnected} />
          </div>
        </>
      )}
      <IonItem button onClick={() => handleAccordionClick(1)} className={connectionQuestionsEl ? 'shiny' : ''} detail>
        <IonLabel>Tasks</IonLabel>
      </IonItem>
      <div className={`accordion-content ${expanded === 1 ? 'open' : ''}`}>
        <aside className="ion-padding" slot="content">
          {connectionQuestionsEl ? connectionQuestionsEl : 'No content at the moment.'}
        </aside>
      </div>
    </>
  );
};

export default ConnProfileTiles;
