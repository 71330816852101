import { IonButton, useIonModal } from '@ionic/react';
import { FC, useRef, useState } from 'react';
import { QuestionsToAnswerTypes } from './UserConnections';
import randomInt from '../utilities/random-int';
import { useMutation, useQueryClient } from 'react-query';
import { useApiContext } from '../hooks/apiContext';
import { useAuthContext } from '../hooks/authContext';
import ConnectionQModal from './ConnectionQModal';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';

interface ShowHideTypes {
  isShiny: boolean
}

const ShowHide: FC<ShowHideTypes> = ({ children, isShiny }) => {
  const [showText, setShowText] = useState(false);

  return (
    <aside>
      <IonButton size="small" className={isShiny ? 'shiny' : ''} fill={!showText ? 'solid' : 'outline'} strong onClick={() => setShowText(!showText)}>
        Answer question(s) - {!showText ? 'show +' : 'hide -'}
      </IonButton>
      {showText &&
        <>
          {children}
        </>}
    </aside>
  );
};

interface QuestionsTypes {
  q: string
  a: string
  type: string
}

interface QuestionsObjectTypes {
  connectionMade: QuestionsTypes;
  connectionMetAgain: QuestionsTypes
  connectionLongTerm: QuestionsTypes
}

interface ConnectionQuestionsTypes {
  connectionId: string
  matchDateTime: string
  questionsObject: QuestionsToAnswerTypes[]
  dismissParent: () => void
}

export interface ConnectionAnswersTypes {
  met?: string;
  metagain?: string;
  metlongterm?: string;
  feedback: string;
  notagain: string;
  meetagain: string;
  rating: number;
}

// instead of this being a modal, let's pop this into the ProfileModalBtn component as a show/hide
const ConnectionQuestions = ({ connectionId, matchDateTime, questionsObject, dismissParent }: ConnectionQuestionsTypes) => {

  const answers = useRef<ConnectionAnswersTypes>({
    met: '',
    metagain: '',
    metlongterm: '',
    feedback: '', // this is general feedback
    notagain: '', // this is feedback for why they are no longer meeting
    meetagain: '',
    rating: 0
  })

  const api = useApiContext();
  const auth = useAuthContext();
  const queryClient = useQueryClient();

  const askRatingRef = useRef('0');

  const mutation = useMutation(async (step: string) => {
    await api.submitConnectionQuestions(connectionId, auth.userDetails?.['custom:memberId'], matchDateTime, answers.current, step, auth.jwt).then(res => {
      console.log('update details res: ', res);
      return res;
    }).catch(err => { throw new Error(err) });
  }, {
    onSuccess: () => {
      alert('Thanks! We got your response! 🥳');
      dismissParent(); // don't need this if using accordion?
      queryClient.resetQueries('connections');
      // window.location.reload();
    },
    onError: (e: Error) => {
      console.log('error! ', e.message);
      alert('There was an error trying to update your details. Please try again later.');
      window.location.reload();
    }
  });

  const handleDismiss = (data: any, role: string) => {
    dismiss(data, role);
  }

  const [present, dismiss] = useIonModal(ConnectionQModal, {
    onDismiss: handleDismiss,
    askRating: askRatingRef,
    meetagain: askRatingRef,
    answersRef: answers
  });

  const questions = useRef<QuestionsObjectTypes>({
    connectionMade: { q: 'Have you met yet?', a: 'We met and plan on meeting again', type: 'met' },
    connectionMetAgain: { q: 'How has your connection been going?', a: 'We met again and plan on meeting again', type: 'metAgain' },
    connectionLongTerm: { q: 'Have you continued meeting?', a: 'Our meetings have continued and will continue', type: 'willContinue' }
  });

  const slides = useRef<JSX.Element[]>([]);

  slides.current = questionsObject.length > 0 ? questionsObject.map((obj, index) => {
    return (
      <div className="question-container">
        <style>
          {`
          .question-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem;
            max-width: 600px;
            margin: 0 auto;
          }
          .question-title {
            text-align: center;
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 1rem;
          }
          .custom-button {
            --background: #FBBF51;
            --background-hover: #FAA41A;
            --background-activated: #FAA41A;
            --color: black;
            --border-radius: 10px;
            --border-width: 2px;
            --border-style: solid;
            --border-color: black;
            margin-bottom: 0.8rem;
            width: 100%;
            max-width: 400px;
            height: 60px;
            font-size: 1rem;
            text-transform: none;
          }
        `}
        </style>
        <h3 className="question-title">{questions.current[`${obj.q}` as keyof QuestionsObjectTypes].q}</h3>
        <IonButton
          className="custom-button"
          expand="block"
          mode="ios"
          size="large"
          strong
          onClick={() => presentModal(`s${(obj.step)}`, '1', '1')}
        >
          {questions.current[`${obj.q}` as keyof QuestionsObjectTypes].a}
        </IonButton>
        <IonButton
          className="custom-button"
          expand="block"
          mode="ios"
          size="large"
          strong
          onClick={() => presentModal(`s${(obj.step)}`, '1', '0')}
        >
          We met, but won't meet again
        </IonButton>
        <IonButton
          className="custom-button"
          expand="block"
          mode="ios"
          size="large"
          strong
          onClick={() => alert('Well, you should. 😊')}
        >
          We haven't met yet
        </IonButton>
      </div>
    )
  }) : [];

  const presentModal = (step: string, metUp: string, willRate: string) => {
    askRatingRef.current = willRate;
    answers.current.meetagain = willRate;
    switch (step.split('s')[1]) {
      case '1':
        answers.current.met = metUp;
        break;

      case '2':
        answers.current.metagain = metUp;
        break;

      case '3':
        answers.current.metlongterm = metUp;
        break;

      default:
        break;
    }
    present({
      onDidDismiss(ev: CustomEvent<OverlayEventDetail>) {
        if (ev.detail.role === 'cancel') { }
        else {
          // place answers from ev.detail.data into answers ref
          // console.log('data: ', JSON.stringify(ev.detail.data));
          // console.log('rating: ', askRatingRef.current);

          const indexToDelete = parseInt(step.split('s')[1]);
          // console.log('to delete: ', indexToDelete);
          slides.current = slides && slides.current.filter((_, index) => index !== indexToDelete - 1);
          mutation.mutate(step);
        }
      },
    });
  }

  const [index, setIndex] = useState(0);

  const handlePrevClick = () => {
    if (slides && index > 0) {
      setIndex(index - 1);
    }
  };

  const handleNextClick = () => {
    if (slides && index < slides.current.length - 1) {
      setIndex(index + 1);
    }
  };

  return (
    <>
      {slides && slides.current[0]}
    </>
  );
}

export default ConnectionQuestions;