import { useLayoutEffect, MouseEvent, useRef } from 'react';
import { useHistory } from 'react-router';
import { checkmarkCircle, informationCircle } from 'ionicons/icons';
import { useAuthContext } from '../hooks/authContext';
import { useMutation } from 'react-query';
import { useApiContext } from '../hooks/apiContext';
import { IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonRow, IonTitle, useIonToast } from '@ionic/react';

interface InfoTypes {
  addorgcode: string
}

const Settings = () => {
  const history = useHistory();
  const auth = useAuthContext();
  const api = useApiContext();
  const [present] = useIonToast();
  const orgcodeToAdd = useRef<undefined | string>();

  useLayoutEffect(() => {
    if(!auth.auth) history.push('/');
  }, []);

  const infoObj: InfoTypes = {
    addorgcode: 'The organization code can be given to you by an admin of a particular dashboard. They might email it to you via a communication, or you can ask for it. It allows you to be a part of the dashboard.'
  }

  const handleInfoClick = (info: string) => {
    present({
      header: 'Info',
      message: infoObj[`${info}` as keyof InfoTypes],
      position: 'bottom',
      layout: 'stacked',
      icon: informationCircle,
      buttons: [
        {
          text: 'Close',
          role: 'cancel'
        }
      ]
    });
  }

  const handleAddOrgcode = async (e: MouseEvent<HTMLIonButtonElement> ) => {
    e.preventDefault();
    if(!orgcodeToAdd.current) {
      alert('Please add an organization code.'); 
      return;
    }

    try {
      
      await mutate(orgcodeToAdd.current);
      
    } catch (error) {
      console.error('There was an error: ', error)
      alert('There was an error: ' + error);
      return;
    }

    present({
      header: 'Organization code added!',
      message: 'You are now a part of that dashboard and will be a part of their matches.',
      duration: 3500,
      position: 'bottom',
      layout: 'stacked',
      icon: checkmarkCircle
    });
  }

  const { mutate, isLoading, isError } = useMutation( async (ocToAdd: string) => {
    return await api.addOrgcode(auth.userDetails?.['custom:memberId'], auth.userDetails?.email, ocToAdd, auth.jwt);
  })
  
  if(isLoading) return <IonLoading isOpen />;
  else if(isError) return <section style={{padding: "1rem"}}>
      <IonTitle>Unfortunately, something went wrong. Please try again later.</IonTitle>
    </section>;
  else return (
    <section className="satoshi" style={{padding: "3rem"}}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <h1>Settings</h1>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h3>You can edit your profile by clicking your name at the top right. <span role="img" aria-label="arrow-up">⬆️</span></h3>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonList lines="none">
              <IonItem>
                <p>
                  Add an organization code to your account 
                  &nbsp;<IonIcon style={{cursor: "pointer"}} icon={informationCircle} onClick={() => handleInfoClick('addorgcode')}/>
                </p>
                <IonLabel position="stacked">Add Organization Code</IonLabel>
                <IonInput clearInput onBlur={(e) => orgcodeToAdd.current = e.target.value as string} type="text" placeholder="Paste org code here" />
                <IonButton onMouseUp={handleAddOrgcode}>Add</IonButton>
              </IonItem>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    </section>
  );
}

export default Settings;