import { Fragment, CSSProperties } from 'react';
import '../style/PercentBar.css';

interface PercentBarTypes {
  title: string
  hexNumber: number
  denominator: number
}
const PercentBar = ({ title, hexNumber, denominator }: PercentBarTypes) => {

  // CSS
  const barBg: CSSProperties = {
    width: '100%',
    backgroundColor: '#ddd',
    borderRadius: '1rem'
  }

  const barFg: CSSProperties = {
    width: `${(hexNumber/denominator)*100}%`,
    height: '10px',
    backgroundColor: '#FBBF51',
    borderRadius: '1rem'
  }

  const barLabel: CSSProperties = {
    fontSize: '1.2rem',
    textTransform: 'capitalize',
    fontWeight: 'bold'
  }
  
  const percentLabel: CSSProperties = {
    fontWeight: 'normal',
    fontSize: '1.2rem'
  }

  return (
    <Fragment>
      <h3 style={barLabel}>{title} - <span style={percentLabel}>{`${((hexNumber/denominator)*100).toPrecision(3)}%`}</span></h3>
      <aside style={barBg}>
        <aside style={{...barFg, textAlign: 'center'}}></aside>
      </aside>
    </Fragment>
  );
}

export default PercentBar;