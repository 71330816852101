import { MapTypes } from './Dashboard'
import { DynamoNumberTypes, DynamoStringTypes } from './dynamodb'

export interface ChatTypes {
  name: DynamoStringTypes
  messageCount: DynamoNumberTypes
  chatId?: DynamoStringTypes
  userId?: DynamoStringTypes
  newChat: boolean
}

export interface ChatTypesExtended extends ChatTypes {
  user: MapTypes
}

// export interface ModalChatTypes extends Omit<ChatTypes, "name" | "messageCount" | "newChat"> { }

export interface DynamoMapStringTypes {
  M: ChatTypes
}

export interface ChatListTypes {
  chatIds: DynamoMapStringTypes[]
}

export enum ChatActionKind {
  UPDATE = 'UPDATE',
  NO_CHANGE = 'NO_CHANGE'
}

export interface ChatActionTypes {
  type: ChatActionKind
  payload: DynamoMapStringTypes[]
}

export interface SingleChatTypes {
  id: DynamoStringTypes
  message: DynamoStringTypes
  sender: DynamoStringTypes
  timestamp: DynamoStringTypes
}

export interface SingleMapChatTypes {
  M: SingleChatTypes
}

export interface SingleMessageObjectTypes {
  message: string | number | readonly string[] | undefined
  sender: string | null | undefined
}