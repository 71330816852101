import { IonAccordion, IonAccordionGroup, IonItem, IonLabel, IonText } from "@ionic/react";
import { UserMatch } from "../services/user-matches";

export type Props = Pick<UserMatch,
    "universities"
    | "education"
    | "hobbies"
    | "city_current"
    | "user"
    | "industry"
    | "admired_most_choice"
    | "desired_abilities_choice"
    | "favorite_things_choice"
    | "happy_places_choice"
    | "fire_rescue_choice"
    | "music_preferences_choice"
    | "must_know_facts_choice"
    | "interests_choice"
>


function ProfileInformationAccordion({
    universities,
    education,
    city_current,
    hobbies,
    user,
    industry,
    admired_most_choice,
    desired_abilities_choice,
    favorite_things_choice,
    fire_rescue_choice,
    happy_places_choice,
    interests_choice,
    music_preferences_choice,
    must_know_facts_choice
}: Props) {
    return (
        <IonAccordionGroup multiple={true}>
            <IonAccordion value="first">
                <IonItem slot="header" color="light">
                    <IonLabel>Professional Snapshot</IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content" style={{
                    textTransform: 'capitalize'
                }}>
                    <ul>
                        <li>
                            <IonText color={'primary'}>Education Level:</IonText>
                            <ul>
                                {education?.L?.map((item, index) => <li key={index}>{item.S}</li>)}
                            </ul>
                        </li>
                        <li>
                            <IonText color={'primary'}>Universities Attended:</IonText>
                            <ul>
                                {universities?.L?.map((item, index) => <li key={index}>{item.S}</li>)}
                            </ul>
                        </li>
                    </ul>
                </div>
            </IonAccordion>
            <IonAccordion value="second">
                <IonItem slot="header" color="light">
                    <IonLabel>Personal Information</IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content" style={{
                    textTransform: "capitalize"
                }}>
                    <ul>
                        <li>
                            <IonText color={'primary'}>Current Location:</IonText> {city_current?.L[0]?.S}</li>
                        <li>
                            <IonText color={'primary'}>Interests:</IonText>
                            <ul>
                                {interests_choice?.L.map((item, index) =>
                                    <li key={index}>
                                        {item.M.title.S}
                                    </li>
                                )}
                            </ul>
                        </li>
                        <li>
                            <IonText color={'primary'}>Birthday:</IonText> {new Date(Number(user.M.dobyear?.S || "2000"), Number(user.M.dobmonth?.S || "1") - 1, Number(user.M.dobday?.S || "1"))
                                .toLocaleDateString('en-US', {
                                    day: 'numeric',
                                    month: 'long'
                                })}</li>
                    </ul>
                </div>
            </IonAccordion>
            <IonAccordion value="third">
                <IonItem slot="header" color="light">
                    <IonLabel>
                        Fun Facts
                    </IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content" style={{
                    textTransform: "capitalize"
                }}>
                    <ul>
                        <li>
                            <IonText color={'primary'}>Favorite Things:</IonText>
                            <ul>
                                {favorite_things_choice?.L.map((item, index) =>
                                    <li key={index}>
                                        {item.S}
                                    </li>
                                )}
                            </ul>
                        </li>
                        <li>
                            <IonText color={'primary'}>Favorite Artists & Bands:</IonText>
                            <ul>
                                {music_preferences_choice?.L.map((item, index) =>
                                    <li key={index}>
                                        {item.S}
                                    </li>
                                )}
                            </ul>
                        </li>
                        <li>
                            <IonText color={'primary'}>Happy Places:</IonText>
                            <ul>
                                {happy_places_choice?.L.map((item, index) =>
                                    <li key={index}>
                                        {item.S}
                                    </li>
                                )}
                            </ul>
                        </li>
                        <li>
                            <IonText color={'primary'}>People I admire:</IonText>
                            <ul>
                                {admired_most_choice?.L.map((item, index) =>
                                    <li key={index}>
                                        {item.S}
                                    </li>
                                )}
                            </ul>
                        </li>
                        <li>
                            <IonText color={'primary'}>Quality or Ability I'd Like to Have:</IonText>
                            <ul>
                                {desired_abilities_choice?.L.map((item, index) =>
                                    <li key={index}>
                                        {item.S}
                                    </li>
                                )}
                            </ul>
                        </li>
                        <li>
                            <IonText color={'primary'}>2-Things to know about me:</IonText>
                            <ul>
                                {must_know_facts_choice?.L.map((item, index) =>
                                    <li key={index}>
                                        {item.S}
                                    </li>
                                )}
                            </ul>
                        </li>
                        <li>
                            <IonText color={'primary'}>One Thing I'd Save in a Fire:</IonText>
                            <ul>
                                {fire_rescue_choice?.L.map((item, index) =>
                                    <li key={index}>
                                        {item.S}
                                    </li>
                                )}
                            </ul>
                        </li>
                    </ul>
                </div>
            </IonAccordion>
        </IonAccordionGroup>
    )
}


export default ProfileInformationAccordion;