/* eslint-disable react-hooks/exhaustive-deps */
import { IonAccordion, IonAccordionGroup, IonBadge, IonCard, IonCardContent, IonCol, IonGrid, IonItem, IonLabel, IonRow, IonSpinner, IonText } from '@ionic/react';
import Avatar from 'react-avatar';
// import { useQuery } from 'react-query';
// import { useApiContext } from '../hooks/apiContext';
import { useAuthContext } from '../hooks/authContext';

import { SingleMemberTypes } from './interfaces/Dashboard';
import PercentBar from './PercentBar';

import { Fragment, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router';
import randomInt from '../utilities/random-int';
import UserPersonalContent from './UserPersonalContent';
import OrgCodeSelect from './OrgCodeSelect';
import { Link } from 'react-router-dom';

// import UserConnections from './UserConnections';
import '../style/Dashboard.css';
import { OrganizationContext } from '../OrganizationContext';
import ProfilePictureModal from './ProfilePictureModal';

interface DashboardTypes {
  data: SingleMemberTypes | undefined
}

export interface ExplanationsTypes {
  honesty: string;
  emotionality: string;
  extraversion: string;
  agreeableness: string;
  conscientiousness: string;
  openness: string;
}

const Dashboard = ({ data }: DashboardTypes) => {
  const history = useHistory();
  const auth = useAuthContext();

  const [orgCode, setOrgCode] = useState<string | undefined>(undefined);
  const { setSelectedOrg } = useContext(OrganizationContext);

  useEffect(() => {
    if (data?.organizations.length === 1) setSelectedOrg(data.organizations[0])
  }, [])

  useLayoutEffect(() => {
    if (!auth.auth) history.push('/');
  }, []);

  const explanations: ExplanationsTypes = {
    "honesty": "Measures individual differences in peoples' sincerity, fairness, greed avoidance, and modesty",
    "emotionality": "Described as often being self-conscious and shy, and tending to have trouble controlling urges and delaying gratification",
    "extraversion": "Tends to be manifested in outgoing, talkative, energetic behavior. Opposite is more reflective and reserved behavior",
    "agreeableness": "Agreeable individuals display a tendency to perceive others in a more positive light",
    "conscientiousness": "Implies a desire to do a task well, and to take obligations to others seriously",
    "openness": "Motivated to seek new experiences and to engage in self-examination"
  }

  if (data) return (
    <>
      <IonGrid className='satoshi'>
        <IonRow className='avatar-half'>
          <IonCol>
            <IonCard>
              <IonCardContent style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
              }}>
                <ProfilePictureModal first_name={data.user.M.fname.S} last_name={data.user.M.lname.S} profile_picture_url={data.profile_picture_url?.S} onDismiss={() => { }} />
                <div>
                  <h1 className="dashboard-title">Welcome, {data?.user.M.fname.S}!</h1>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4
                  }}>
                    <Link to={'/Connections'}>
                      <IonBadge slot="start">Your Connections: {data.connectionIds.L.length}</IonBadge>
                    </Link>
                  </div>
                </div>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='12'>
            {((data.organizations.length ?? 0) < 2 || orgCode) ?
              <UserPersonalContent persona={data.archetype.S} orgcode={data.orgcode?.L[0]?.S ?? 'unknown'} />
              :
              <OrgCodeSelect organizations={data.organizations} setOrgCode={setOrgCode} />
            }
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12" sizeMd='6' style={{
            padding: "0"
          }}>
            <IonCard style={{
            }}>
              <IonCardContent>
                {Object.entries(data.hexaco.M).sort().filter((([k, v]) => k !== 'latestAssessment')).map(([key, value], index) => {
                  if (key === 'neuroticism') key = 'emotionality';
                  return (
                    <Fragment key={`${index}-${randomInt()}`}>
                      <PercentBar title={key} hexNumber={value.N} denominator={30} />
                      <p style={{
                        marginTop: "8px",
                        marginBottom: "6px"
                      }}>{explanations[`${key}` as keyof ExplanationsTypes]}</p>
                    </Fragment>
                  );
                })}
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol size='12' sizeMd='6'>
            <IonCard style={{}}>
              <IonCardContent>
                <IonAccordionGroup multiple={true}>
                  <IonAccordion value="first">
                    <IonItem slot="header" color="light">
                      <IonLabel>Professional Snapshot</IonLabel>
                    </IonItem>
                    <div className="ion-padding" slot="content" style={{
                      textTransform: 'capitalize'
                    }}>
                      <ul>
                        <li>
                          <IonText color={'primary'}>Education Level:</IonText>
                          <ul>
                            {data?.education?.L?.map((item, index) => <li key={index}>{item.S}</li>)}
                          </ul>
                        </li>
                        <li>
                          <IonText color={'primary'}>Universities Attended:</IonText>
                          <ul>
                            {data?.universities?.L?.map((item, index) => <li key={index}>{item.S}</li>)}
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </IonAccordion>
                  <IonAccordion value="second">
                    <IonItem slot="header" color="light">
                      <IonLabel>Personal Information</IonLabel>
                    </IonItem>
                    <div className="ion-padding" slot="content" style={{
                      textTransform: "capitalize"
                    }}>
                      <ul>
                        <li>
                          <IonText color={'primary'}>Current Location:</IonText> {data?.city_current?.L[0]?.S}</li>
                        <li>
                          <IonText color={'primary'}>Interests:</IonText>
                          <ul>
                            {data?.interests_choice?.L.map((item, index) =>
                              <li key={index}>
                                {item.M.title.S}
                              </li>
                            )}
                          </ul>
                        </li>
                        <li>
                          <IonText color={'primary'}>Birthday:</IonText> {new Date(Number(data.user.M.dobyear.S), Number(data.user.M.dobmonth.S) - 1, Number(data.user.M.dobday.S))
                            .toLocaleDateString('en-US', {
                              day: 'numeric',
                              month: 'long'
                            })}</li>
                      </ul>
                    </div>
                  </IonAccordion>
                  <IonAccordion value="third">
                    <IonItem slot="header" color="light">
                      <IonLabel>
                        Fun Facts
                      </IonLabel>
                    </IonItem>
                    <div className="ion-padding" slot="content" style={{
                      textTransform: "capitalize"
                    }}>
                      <ul>
                        <li>
                          <IonText color={'primary'}>Favorite Things:</IonText>
                          <ul>
                            {data?.favorite_things_choice?.L.map((item, index) =>
                              <li key={index}>
                                {item.S}
                              </li>
                            )}
                          </ul>
                        </li>
                        <li>
                          <IonText color={'primary'}>Favorite Artists & Bands:</IonText>
                          <ul>
                            {data?.music_preferences_choice?.L.map((item, index) =>
                              <li key={index}>
                                {item.S}
                              </li>
                            )}
                          </ul>
                        </li>
                        <li>
                          <IonText color={'primary'}>Happy Places:</IonText>
                          <ul>
                            {data?.happy_places_choice?.L.map((item, index) =>
                              <li key={index}>
                                {item.S}
                              </li>
                            )}
                          </ul>
                        </li>
                        <li>
                          <IonText color={'primary'}>People I admire:</IonText>
                          <ul>
                            {data?.admired_most_choice?.L.map((item, index) =>
                              <li key={index}>
                                {item.S}
                              </li>
                            )}
                          </ul>
                        </li>
                        <li>
                          <IonText color={'primary'}>Quality or Ability I'd Like to Have:</IonText>
                          <ul>
                            {data?.desired_abilities_choice?.L.map((item, index) =>
                              <li key={index}>
                                {item.S}
                              </li>
                            )}
                          </ul>
                        </li>
                        <li>
                          <IonText color={'primary'}>2-Things to know about me:</IonText>
                          <ul>
                            {data?.must_know_facts_choice?.L.map((item, index) =>
                              <li key={index}>
                                {item.S}
                              </li>
                            )}
                          </ul>
                        </li>
                        <li>
                          <IonText color={'primary'}>One Thing I'd Save in a Fire:</IonText>
                          <ul>
                            {data?.fire_rescue_choice?.L.map((item, index) =>
                              <li key={index}>
                                {item.S}
                              </li>
                            )}
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </IonAccordion>
                </IonAccordionGroup>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );

  else return <IonSpinner name="crescent" />
}

export default Dashboard;