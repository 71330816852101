import { IonIcon, IonItem, IonLabel, IonList, IonNote, IonSpinner, IonTitle } from '@ionic/react';
import { useState } from 'react';
import { MeetingAssistantContentTypes, MeetingAssistantErrorTypes } from './interfaces/MeetingAssistant';
import { useQuery } from 'react-query';
import { useApiContext } from '../hooks/apiContext';
import { useAuthContext } from '../hooks/authContext';
import randomInt from '../utilities/random-int';
import { isDatePastLimit } from '../utilities/dateCheck';
import { star } from 'ionicons/icons';

// use these to query the content table
export interface MeetingAssistantTypes {
  persona: string | undefined
  orgcode: string | undefined
  dateConnected?: string
}

const MeetingAssistant = ( { persona, orgcode, dateConnected }: MeetingAssistantTypes) => {

  const api = useApiContext();
  const auth = useAuthContext();

  const { data, isError, isLoading, isFetching, isSuccess } = useQuery<MeetingAssistantContentTypes | MeetingAssistantErrorTypes>('meeting-data', async () => {
    return await api.getMeetingData(orgcode!, persona!, auth.jwt)
      .then(res => {
        return res;
      }).catch(err => {throw new Error(err)});
  }, {
    retry: 2,
    enabled: !!orgcode,
    refetchOnWindowFocus: false
  });

  const [expanded, setExpanded] = useState<number | null>(null);
  const handleAccordionClick = (ind: number) => {
    setExpanded(expanded === ind ? null : ind);
  }

  if(isSuccess && (data as MeetingAssistantErrorTypes).message) return (<aside className="ion-padding" slot="content" ><IonNote>{(data as MeetingAssistantErrorTypes).message}</IonNote></aside>);

  else if(isSuccess) return (
    <section>
      <aside slot="content">
        {/* Welcome to your meeting assistant! The information provided below is to assist you in your upcoming meetings that go beyond a first meeting.<br /><br />
        <IonNote><span role="img" aria-label="warning">⚠️ </span>If you do not see content below, You'll be notified once it's available.</IonNote> */}
        <hr />
        {((data as MeetingAssistantContentTypes).data.L.length > 0 ? (data as MeetingAssistantContentTypes).data.L.map((c, index) => {
          // console.log('now: ', Date.now());
          // console.log('launch: ', (new Date(c.M.dateToLaunch.S!.toUpperCase()).getTime())/(1000 * 3600 * 24));
          // console.log('dt: ', isDatePastLimit(dateConnected, parseInt(c.M.launchDaysAfterConnection.N, 10)));
          if(isDatePastLimit(dateConnected!, parseInt(c.M.launchDaysAfterConnection.N, 10))) {
            return (
              <aside key={`${randomInt()}-${index}`}>
                <IonItem button onClick={() => handleAccordionClick(index)} detail>
                  <IonIcon color="primary" icon={star} slot="start"></IonIcon>
                  <IonLabel className="ion-text-wrap">What <em style={{textTransform: "capitalize"}}>{persona?.split(' ')[1]}s</em> are reading about themselves <strong>| Ch. {c.M.meetingNumber.N}</strong>:</IonLabel>
                </IonItem>
                <div className={`accordion-content ${expanded === index ? 'open' : ''}`}>
                  <aside className="ion-padding" slot="content" style={{textAlign: "start", whiteSpace: "pre-line"}}>
                    <p>{c.M.content.M[`${(data as MeetingAssistantContentTypes).currentContentUse.S}`].S}</p>
                  </aside>
                </div>
              </aside>
            );
          } else return undefined;
        }).filter(x => typeof x !== undefined) : <IonNote>Content unavailable at the moment.</IonNote>)}
      </aside>
    </section>
  );
  else if(isError) return (<IonNote>There was an error loading your material. Please try again later.</IonNote>);
  else if(isLoading || isFetching) return (<IonSpinner name="bubbles" />);
  else return (<IonNote>An unknown error has occurred. We apolgize for the inconvenience. Please try again later.</IonNote>);
}

export default MeetingAssistant;