import { IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonLoading, IonMenuButton, IonPage, IonRow, IonSplitPane, IonTitle, IonToolbar } from '@ionic/react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import Connections from '../components/Connections';
import Dashboard from '../components/Dashboard';
import EditToolbar from '../components/EditToolbar';
import Login from '../components/Login';
import NewPassword from '../components/NewPassword';
import Register from '../components/Register';
import Settings from '../components/Settings';
import Activities from '../components/activities/Activities';
import { SingleMemberTypes } from '../components/interfaces/Dashboard';
import Messaging from '../components/messaging/Messaging';
import { useApiContext } from '../hooks/apiContext';
import { useAuthContext } from '../hooks/authContext';

/* Style Imports... */
import './Home.css';
import NavSideMenu from '../components/NavSideMenu';
import CommunityPage from '../components/CommunityPage';

const Home: React.FC = () => {

  const { name } = useParams<{ name: string; }>();
  const api = useApiContext();
  const auth = useAuthContext();

  const { data, isLoading, isFetching, isError } = useQuery<SingleMemberTypes>('userData', async () => {
    return await api.getUser(auth.userDetails?.['custom:memberId'], auth.userDetails?.email, auth.jwt).then(res => res).catch(err => { throw new Error(err) });
  }, {
    refetchOnWindowFocus: false,
    retry: 2,
    // The query will not execute until custom:memberId exists
    enabled: (!!auth.userDetails?.['custom:memberId'] && !!auth.jwt)
  });

  if (isLoading || isFetching) return <IonLoading isOpen />;
  else if (isError) return <IonTitle>Unfortunately, something went wrong. Please try again later.</IonTitle>;

  return (
    <>
      <IonSplitPane when="md" contentId='main-content'>
        {
          (data?.id?.S && auth.auth) && <NavSideMenu />
        }
        <IonPage id="main-content">
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
              </IonButtons>
              <IonGrid>
                <IonRow>
                  {(data?.id?.S && auth.auth) &&
                    <IonCol pushXl="7" pushLg="5" pushMd="4" pushSm="4" sizeXl="4" sizeLg="6" sizeMd="6" sizeSm="6">
                      <EditToolbar userData={data} />
                    </IonCol>
                  }
                </IonRow>
              </IonGrid>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {(() => {
              switch (name) {
                case 'Login':
                  return <Login />
                case 'NewPassword':
                  return <NewPassword />
                case 'Persona':
                case 'Dashboard':
                  return <Dashboard data={data} />
                case 'Connections':
                  return <Connections data={data} />
                case 'Messages':
                  return <Messaging />
                case 'Community':
                  return <CommunityPage />
                case 'Settings':
                  return <Settings />
                case 'Register':
                  return <Register />
                case 'Activities':
                  return <Activities />
                default:
                  return <Register />
              }
            })()}
          </IonContent>
        </IonPage>
      </IonSplitPane>
    </>
  )
};

export default Home;
