import { createContext } from "react";
import { DynamoListStringConnectionTypes, StringTypes } from "../components/interfaces/Dashboard";
import { UserDetailsTypes } from "../components/UserDetailsModalBtn";
import { UserMatch } from "./user-matches";

export interface HttpHeaders {
  [key: string]: string;
}

export type MatchType = "kindred" | "growth";

export type GetMachesBody = {
  id?: string;
  matchType: MatchType;
  orgcode?: string;
  matchesLimit?: string;
  connectionsLimit?: string;
};


export class ApiService {

  _headers: HttpHeaders

  constructor() {
    this._headers = { 'Content-Type': 'application/json' };
    if (process.env.NODE_ENV !== 'production') this._headers['X-API-KEY'] = process.env.REACT_APP_API_KEY!;
  }

  handleStatus = (status: number) => {
    switch (status) {
      case 401:
        console.error('Expired token');
        alert('Your session has expired. Press Okay to refresh.');
        window.location.reload();
        break;
      case 500:
      case 501:
      case 502:
        console.error('Server error');
        alert('There was a server error. Please try again later.');
        throw new Error('Server error');
      default:
        return true;
    }
  }

  async getMaches(body: GetMachesBody, jwt: string | undefined): Promise<UserMatch[]> {
    this._headers['Authorization'] = `${jwt}`;
    const res = await fetch(
      process.env.NODE_ENV === "production" ? `/v1/user-profile?type=get-matches` : `${process.env.REACT_APP_GET_USER_MATCHES}?type=get-matches`,
      {
        method: "PUT",
        headers: this._headers,
        body: JSON.stringify(body)
      }
    );
    if (!res.ok) {
      throw new Error('Network response was not ok')
    }
    const data = await res.json();
    return JSON.parse(data.body);
  }

  getUser = async (id: string | undefined, email: string | undefined, jwt: string | undefined) => {
    if (!id || !email || !jwt) { console.log(`User ${!id ? 'id' : 'email'} not provided.`); return; }
    const params = new URLSearchParams({
      type: 'userSpecific',
      memberId: id,
      alreadyViewed: 'true',
      email
    });

    this._headers['Authorization'] = `${jwt}`;

    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_URL_USER + `?${params}` : `/v1/user-profile` + `?${params}`, {
      method: 'POST',
      headers: this._headers,
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  getUserConnections = async (id: string | undefined, connectionIds: DynamoListStringConnectionTypes, jwt: string | undefined, isTopConnections: boolean) => {
    if (!id || !jwt) { console.log(`User ${!id} not provided.`); throw new Error; }
    const params = new URLSearchParams({
      type: 'connections'
    });
    const payload = JSON.stringify({ memberId: id, connectionIds: connectionIds.L, isTopConnections });

    this._headers['Authorization'] = `${jwt}`;

    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_URL_USER + `?${params}` : `/v1/user-profile` + `?${params}`, {
      method: 'POST',
      headers: this._headers,
      body: payload
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  getUserOrgs = async (orgcodeList: StringTypes[], jwt: string | undefined) => {
    if (!jwt) { console.log(`jwt not provided.`); throw new Error; }
    const params = new URLSearchParams({
      type: 'orgs'
    });
    const payload = JSON.stringify({ orgcodeList });

    this._headers['Authorization'] = `${jwt}`;

    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_URL_USER + `?${params}` : `/v1/user-profile` + `?${params}`, {
      method: 'POST',
      headers: this._headers,
      body: payload
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }


  uploadProfilePicture = async (body: FormData, jwt: string | undefined) => {
    if (!jwt) {
      console.log(`jwt not provided.`);
      throw new Error("JWT not provided");
    }
    const headers = {
      Authorization: `${jwt}`
    };
    return await fetch(process.env.NODE_ENV !== 'production' ? `${process.env.REACT_APP_UPLOAD_PROFILE_PICTURE_URL}` : `/v1/profile/picture`, {
      method: 'POST',
      headers: headers,
      body: body
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => {
        throw new Error(err);
      });
  };


  getRecipient = async (id: string | undefined, jwt: string | undefined) => {
    if (!id || !jwt) { console.log(`Recipient ${!id ? 'id' : 'jwt'} not provided.`); return; }
    const params = new URLSearchParams({
      type: 'recipient'
    });

    const payload = JSON.stringify({ memberId: id });
    this._headers['Authorization'] = `${jwt}`;

    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_URL_USER + `?${params}` : `/v1/user-profile` + `?${params}`, {
      method: 'POST',
      headers: this._headers,
      body: payload
    }).then(res => {
      const statusIsGood = this.handleStatus(res.status);
      if (!statusIsGood) throw new Error(`Status Error: ${res.status}`);
      return res.json();
    }).catch(err => { throw new Error(err) });
  }

  getChat = async (chatId: string | undefined, jwt: string | undefined) => {
    if (!chatId || !jwt) { console.log('ChatId/Jwt not provided.'); return; }
    const params = new URLSearchParams({
      type: 'chat'
    });
    const payload = JSON.stringify({ chatId });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_URL_USER + `?${params}` : `/v1/user-profile` + `?${params}`, {
      method: 'POST',
      headers: this._headers,
      body: payload
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  getS3Chat = async (sEmail: string | undefined, rEmail: string | undefined, marker: number, jwt: string | undefined) => {
    if (!sEmail || !rEmail || !jwt) { console.log('Emails/Jwt not provided.'); return; }
    const params = new URLSearchParams({
      type: 'latest-s3'
    });
    const payload = JSON.stringify({ sEmail, rEmail, marker });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_URL_USER + `?${params}` : `/v1/user-profile` + `?${params}`, {
      method: 'POST',
      headers: this._headers,
      body: payload
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  getChatId = async (rId: string, sId: string, jwt: string) => {
    if (!rId || !sId || !jwt) { console.log('Ids/Jwt not provided.'); return; }
    const params = new URLSearchParams({
      type: 'chatId'
    });
    const payload = JSON.stringify({ rId, sId });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_URL_USER + `?${params}` : `/v1/user-profile` + `?${params}`, {
      method: 'POST',
      headers: this._headers,
      body: payload
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  updateDetails = async (id: string | undefined, email: string | undefined, details: UserDetailsTypes, jwt: string | undefined) => {
    if (!id || !jwt) { console.log('User id not provided.'); throw new Error('No user id is present.'); }
    const params = new URLSearchParams({
      type: 'dbedit'
    });
    const payload = JSON.stringify({ ...{ id, email }, details });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_URL_USER + `?${params}` : `/v1/user-profile` + `?${params}`, {
      method: 'PUT',
      headers: this._headers,
      body: payload
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  resetMessageNumber = async (id: string | undefined, chatId: string | undefined, jwt: string | undefined) => {
    if (!id || !jwt) { console.log('User id/jwt not provided.'); throw new Error('No user id or jwt present.'); }
    const params = new URLSearchParams({
      type: 'message-reset'
    });
    const payload = JSON.stringify({ id, chatId });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_URL_USER + `?${params}` : `/v1/user-profile` + `?${params}`, {
      method: 'PUT',
      headers: this._headers,
      body: payload
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  setViewed = async (id: string | undefined, matchDateTime: string | undefined, jwt: string | undefined) => {
    if (!id || !matchDateTime || !jwt) { console.log('User id/matchDateTime/jwt not provided.'); throw new Error('No user id, date, or jwt present.'); }
    const params = new URLSearchParams({
      type: 'viewed'
    });
    const payload = JSON.stringify({ id, matchDateTime });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_URL_USER + `?${params}` : `/v1/user-profile` + `?${params}`, {
      method: 'PUT',
      headers: this._headers,
      body: payload
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  submitConnectionQuestions = async (id: string | undefined, currentUserId: string | undefined, matchDateTime: string | undefined, answers = {}, step: string, jwt: string | undefined) => {
    if (!id || !matchDateTime || !jwt) { console.log('User id/matchDateTime/jwt not provided.'); throw new Error('No user id, date, or jwt present.'); }
    const params = new URLSearchParams({
      postType: step
    });
    const payload = JSON.stringify({ connectionId: id, matchDateTime, who: currentUserId, ...answers });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_SUBMIT_ANSWERS + `?${params}` : `/v1/user-connections-answers` + `?${params}`, {
      method: 'PUT',
      headers: this._headers,
      body: payload
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  addOrgcode = async (currentUserId: string | undefined, email: string | undefined, orgcode: string | undefined, jwt: string | undefined) => {
    if (!currentUserId || !orgcode || !jwt) { console.log('User id/orgcode/jwt not provided.'); throw new Error('No user id, orgcode, or jwt present.'); }
    const params = new URLSearchParams({
      type: 'add-orgcode'
    });
    const payload = JSON.stringify({ id: currentUserId, email, orgcode });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_URL_USER + `?${params}` : `/v1/user-profile` + `?${params}`, {
      method: 'PUT',
      headers: this._headers,
      body: payload
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }

  getMeetingData = async (orgcode: string, persona: string, jwt: string | undefined) => {
    if (!orgcode || !jwt) { console.log('orgcode/Jwt not provided.'); return; }
    const params = new URLSearchParams({
      type: 'meeting-content'
    });
    const payload = JSON.stringify({ orgcode, persona });
    this._headers['Authorization'] = `${jwt}`;
    return await fetch(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_URL_USER + `?${params}` : `/v1/user-profile` + `?${params}`, {
      method: 'POST',
      headers: this._headers,
      body: payload
    }).then(res => {
      this.handleStatus(res.status);
      return res.json();
    })
      .catch(err => { throw new Error(err) });
  }
}

const ApiInstance = new ApiService();

export default createContext(ApiInstance);