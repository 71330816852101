import { createContext, Dispatch, FC, MutableRefObject, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { MapTypes } from './components/interfaces/Dashboard';
import { ChatActionKind, ChatActionTypes, DynamoMapStringTypes, SingleChatTypes, SingleMessageObjectTypes } from './components/interfaces/Messaging';
import { chatReducer } from './components/reducers/chatProvider';
import { useAuthContext } from './hooks/authContext';
import { updateMessageCount } from './utilities/updateMessageCount';
import chatSound from './data/sounds/sound.wav';

interface ChatContextTypes {
  chatCount: number,
  currentOpenChatId: MutableRefObject<string | undefined>,
  messages: DynamoMapStringTypes[],
  isReconnecting: boolean,
  setMessageCounter?: React.Dispatch<React.SetStateAction<number>>,
  sendMessage?: (recipientId: string | undefined, chatId: string | undefined, message: SingleMessageObjectTypes, rUser?: MapTypes) => void
  updateMessages?: Dispatch<ChatActionTypes>
}

export const ChatContext = createContext<ChatContextTypes>({ chatCount: 0, currentOpenChatId: undefined as unknown as MutableRefObject<undefined>, messages: [], isReconnecting: false });

const ChatProvider: FC = ({ children }) => {
  const auth = useAuthContext();
  const queryClient = useQueryClient();
  const socket = useRef<WebSocket | null>(null);
  const reconnectTimeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const isReconnecting = useRef(false);
  const isClientSet = useRef(false);
  const currentOpenChatId = useRef<string | undefined>(undefined);
  const [messageCounter, setMessageCounter] = useState(0);
  const [state, dispatch] = useReducer(chatReducer, []);
  const currentMessageState = useRef<DynamoMapStringTypes[]>([]);

  const chatty = useMemo(() => new Audio(chatSound), []);

  const MAX_RECONNECT_ATTEMPTS = 5;
  const INITIAL_RECONNECT_DELAY = 1000;
  // 2 minutes in milliseconds
  const PING_INTERVAL = 120000;
  const reconnectAttempts = useRef(0);
  const reconnectDelay = useRef(INITIAL_RECONNECT_DELAY);
  const pingIntervalId = useRef<NodeJS.Timeout | null>(null);

  const onSocketOpen = () => {
    isReconnecting.current = false;
    reconnectAttempts.current = 0;
    reconnectDelay.current = INITIAL_RECONNECT_DELAY;

    socket.current?.send(JSON.stringify({
      action: '$connect',
      userId: auth.userDetails?.['custom:memberId'],
      name: auth.userDetails?.family_name,
      email: auth.userDetails?.email
    }));

    startPingInterval();
  };

  const onSocketClose = () => {
    console.log('Socket Closed');
    stopPingInterval();
    reconnect();
  };

  const startPingInterval = () => {
    if (pingIntervalId.current) {
      clearInterval(pingIntervalId.current);
    }
    pingIntervalId.current = setInterval(() => {
      sendPing();
    }, PING_INTERVAL);
  };

  const stopPingInterval = () => {
    if (pingIntervalId.current) {
      clearInterval(pingIntervalId.current);
      pingIntervalId.current = null;
    }
  };

  const sendPing = () => {
    if (socket.current?.readyState === WebSocket.OPEN) {
      socket.current.send(JSON.stringify({ action: 'ping' }));
    } else {
      console.log('WebSocket is not open. Attempting to reconnect...');
      reconnect();
    }
  };

  const reconnect = () => {
    if (reconnectAttempts.current >= MAX_RECONNECT_ATTEMPTS) {
      console.error('Max reconnection attempts reached');
      errorAlert();
      return;
    }

    isReconnecting.current = true;
    console.log(`Attempting to reconnect in ${reconnectDelay.current / 1000} seconds...`);

    reconnectTimeout.current = setTimeout(() => {
      reconnectAttempts.current++;
      reconnectDelay.current *= 2;
      onConnect();
    }, reconnectDelay.current);
  };

  const onMessage = (data: any) => {
    const messageData = JSON.parse(data.data);
    if (messageData.message === 'Internal server error') return;
    if (messageData.action === 'pong') {
      console.log('Received pong from server');
      return;
    }
    console.log(messageData);
    switch (messageData.messageType) {
      case 'message':
        if (messageData.chatId === undefined) {
          try {
            chatty.play();
          } catch (error) {
            console.error('sound error: ', error);
          }
          updateMessageCount(1, currentMessageState.current, messageData.chatId, dispatch);
          setMessageCounter(mc => mc + 1);
        }
        else if (messageData.chatId === currentOpenChatId.current) {
          if (document.hidden) {
            try {
              chatty.play();
            } catch (error) {
              console.error('sound error: ', error);
            }
          }
          queryClient.setQueryData('message-data', (oldData: any) => {
            const setMessageObject = {
              M: {
                id: { S: messageData.chatId },
                message: { S: messageData.message },
                sender: { S: messageData.sender },
                timestamp: { S: new Date().toISOString() }
              }
            }
            if (oldData) {
              return [...oldData as SingleChatTypes[], setMessageObject]
            } else {
              return [setMessageObject]
            }
          });
        } else {
          try {
            chatty.play();
          } catch (error) {
            console.error('sound error: ', error);
          }
          updateMessageCount(1, currentMessageState.current, messageData.chatId, dispatch);
          setMessageCounter(mc => mc + 1);
        }
        break;
      case 'cleared':
        if (messageData.chatId === currentOpenChatId.current) {
          queryClient.setQueryData<number>('marker', (previousMarker) => {
            return (previousMarker === -1) ? previousMarker! : previousMarker! + 1;
          });
        }
        break;
      case 'chats':
        if (messageData.chatId) currentOpenChatId.current = messageData.chatId;
        // or if the chatId is not in the current state
        const chatIdAlreadyInState = currentMessageState.current.some(({ M }) => messageData.chatId === M.chatId?.S);
        if (!isClientSet.current || !chatIdAlreadyInState) isClientSet.current = true;
        else return;
        dispatch({
          type: ChatActionKind.UPDATE,
          payload: messageData.chatIds
        });
        currentMessageState.current = messageData.chatIds;
        break;

      default:
        dispatch({
          type: ChatActionKind.NO_CHANGE,
          payload: messageData.chatIds
        });
        break;
    }
  };

  const onError = (e: any) => {
    console.error('WebSocket error:', e); // { isTrusted: true } browser error usually
    // Optionally trigger reconnection on error
    // reconnect();
  };

  const errorAlert = () => {
    alert('Connection lost. The page will refresh to attempt to reestablish connection.');
    window.location.reload();
  };

  const sendMessage = (rId: string | undefined, chatId: string | undefined, message: SingleMessageObjectTypes, rUser?: MapTypes) => {
    if ((message.message as string).length > 280) {
      alert('Your message is above 280 characters. Please shorten it. Thanks!');
      return;
    }

    if (auth.userDetails && Object.values(auth.userDetails).some(v => v === 'undefined')) {
      errorAlert();
      return;
    }

    if (rUser?.M && Object.values(rUser.M).some(v => v.S === 'undefined')) {
      errorAlert();
      return;
    }

    if (socket.current?.readyState !== WebSocket.OPEN) {
      errorAlert();
      return;
    }

    const messageToSend = JSON.stringify({
      action: 'sendPrivate',
      sId: auth.userDetails?.['custom:memberId'],
      sEmail: auth.userDetails?.email,
      sFName: auth.userDetails?.given_name,
      sLName: auth.userDetails?.family_name,
      rId,
      rEmail: rUser?.M.email.S,
      rFName: rUser?.M.fname.S,
      rLName: rUser?.M.lname.S,
      chatId,
      message: {
        message: message.message as string,
        sender: message.sender
      }
    });

    socket.current?.send(messageToSend);
  };

  const onConnect = () => {
    if (socket.current?.readyState !== WebSocket.OPEN) {
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
      if (pingIntervalId.current) {
        clearInterval(pingIntervalId.current);
      }

      socket.current = new WebSocket(process.env.REACT_APP_WS!);
      socket.current.addEventListener('open', onSocketOpen);
      socket.current.addEventListener('close', onSocketClose);
      socket.current.addEventListener('message', onMessage);
      socket.current.addEventListener('error', onError);
    }
  };

  useEffect(() => {
    if (auth.userDetails?.given_name) onConnect();
    return () => {
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
      if (pingIntervalId.current) {
        clearInterval(pingIntervalId.current);
      }
      socket.current?.close();
    };
  }, [auth.userDetails?.given_name]);

  if (!auth) return <p>...</p>

  return (
    <ChatContext.Provider value={{
      chatCount: messageCounter,
      currentOpenChatId: currentOpenChatId,
      setMessageCounter,
      sendMessage,
      messages: state,
      updateMessages: dispatch,
      isReconnecting: isReconnecting.current
    }}>
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;