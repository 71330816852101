import { useEffect, useRef } from 'react';
import { IonItem, IonNote } from '@ionic/react';
import { useQuery, useQueryClient } from 'react-query';
import { useApiContext } from '../../hooks/apiContext';
import { SingleMapChatTypes } from '../interfaces/Messaging';

interface S3ListTypes {
  sEmail: string | undefined
  rEmail: string | undefined
  jwt: string | undefined
}

const S3List = ({sEmail , rEmail, jwt}: S3ListTypes) => {

  const queryClient = useQueryClient();
  
  const api = useApiContext();

  const dataRef = useRef<SingleMapChatTypes[]>([]);

  useEffect(() => {
    // set marker at -1 to start in order to get last key in backend
    queryClient.setQueryData<number>('marker', () => -1);
  }, []);

  // using ref and adding data to ref so queryClient.refetchQueries('s3-messages') can be called in parent component
  // skipping use of useImperativeHandle and useInfiniteQuery (for now)
  const { isSuccess, isError, isLoading, isFetching } = useQuery<SingleMapChatTypes[]>('s3-messages', async () => {
    const temp = await api.getS3Chat(sEmail, rEmail, queryClient.getQueryData('marker')!, jwt).then(res => JSON.parse(res)).catch(e => { throw new Error(e) });
    if(temp[0]?.reachedLast) return;
    dataRef.current = [...temp, ...dataRef.current];
    return temp;
  }, {
    retry: 2,
    refetchOnWindowFocus: false
  })

  if(isLoading || isFetching) return <IonNote>Loading more messages...<span role="img" aria-label="interesting">🙄</span></IonNote>;

  else if(isSuccess && dataRef.current.length > 0) return (
    <>
      {dataRef.current?.map(({M}) => {
        return (
          <IonItem key={M.id.S} className="chat">
            <span style={{textTransform: "capitalize", fontWeight: "bold"}}>{M.sender.S?.split(' ')[0]}:&nbsp;</span><span>{M.message.S}</span>
          </IonItem>
        );
      })}
    </>
  );

  else if(isError) return (
    <IonItem className="chat">
      <IonNote>Looks like there was an error.<span role="img" aria-label="eyes">👀</span> Please try again later.</IonNote>
    </IonItem>
    );
    
  else return (
    <IonItem className="chat">
      <IonNote>No older messages...keep connecting! <span role="img" aria-label="party">🥳</span></IonNote>
    </IonItem>
  );
}

export default S3List;