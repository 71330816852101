import { Dispatch } from 'react';
import { ChatActionKind, ChatActionTypes, DynamoMapStringTypes } from '../components/interfaces/Messaging';

// updateBy number or reset to 0
export const updateMessageCount = (updateByOrTo: number, chatState: DynamoMapStringTypes[], chatId: string, dispatch: Dispatch<ChatActionTypes> | undefined) => {
  // update ChatList based on new message
  // check chatId in "state" variable
  const chatIdPresent = chatState.some(({M}) => M.chatId?.S === chatId);
  // if there, then change M.messageCount.N by adding 1
  if(chatIdPresent) {
    const chat = chatState.find(({M}) => M.chatId?.S === chatId);
    if(!chat?.M) return;
    chat.M.messageCount.N = updateByOrTo > 0 ? (parseInt(chat.M.messageCount.N.toString(), 10) ?? 0) + updateByOrTo : updateByOrTo;
    // place back into state, then use dispatch
    // get index using chatId
    const chatToChangeIndex = chatState.findIndex(({M}) => M.chatId?.S === chatId);
    chatState[chatToChangeIndex] = chat;
    // this will re-render the chat list with the appropriate message count for the chatId
    dispatch && dispatch({
      type: ChatActionKind.UPDATE,
      payload: chatState
    });
  }
}

export const anyUnreadMessages = (chatState: DynamoMapStringTypes[]) => {
  const hasMessageAboveZero = chatState.some(({M}) => parseInt(M.messageCount.N.toString()) > 0);
  return hasMessageAboveZero ? true : false;
}