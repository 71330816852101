import { IonButton, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonTitle } from '@ionic/react';
import { useState } from 'react';
import { capitalizeAllFirstWords, capitalizeFirstLetter } from '../utilities/capitalization';
import randomInt from '../utilities/random-int';
import { SingleMemberTypes } from './interfaces/Dashboard';

interface ChosenObjectTypes {
  on: boolean
  question: string
}

interface ChosenTypes {
  buddy: ChosenObjectTypes
  years: ChosenObjectTypes
  mentor: ChosenObjectTypes
  department: ChosenObjectTypes
  intern: ChosenObjectTypes
}

interface BinaryAddedTypes {
  buddy: string[]
  mentor: string[]
  intern: string[]
}

const AnswerQuestions: React.FC<{userData: SingleMemberTypes}> = ({userData}) => {
  
  const [chosenQuestions, setChosenQuestions] = useState({
    buddy: {on: false, question: 'Are you a new hire?'},
    years: {on: false, question: 'How long have you been at your company?'},
    mentor: {on: false, question: 'Are you a mentor or a mentee?'},
    department: {on: false, question: 'Which department are you in?'},
    intern: {on: false, question: 'Are you an intern?'}
  });

  const binaryAddedQuestions = {
    buddy: ['yes', 'no'],
    mentor: ['mentor', 'mentee'],
    intern: ['yes', 'no']
  }

  return (
    <section>
      <IonTitle size="small">Answer these questions if your organization asks for them. Choose which ones to answer.</IonTitle> <br />
      <aside>
        {Object.keys(chosenQuestions).map((q, index) => {
          return <IonButton key={`${index}-${randomInt()}`} size="small" onClick={() => setChosenQuestions(s => ({...s, [`${q}`]: {on: !s[`${q}` as keyof ChosenTypes].on, question: s[`${q}` as keyof ChosenTypes].question}}))}>{chosenQuestions[`${q}` as keyof ChosenTypes].question}</IonButton>
        })}
      </aside>
      <hr />
      <aside>
        {Object.entries(chosenQuestions).map(([k, v], index) => {
          { 
            if(['buddy', 'years', 'mentor', 'department', 'intern'].includes(k)) {
              if(['buddy', 'mentor', 'intern'].includes(k) && v.on) {
                return (
                  <IonItem key={`${index}-${randomInt()}`}>
                    <IonLabel position="stacked">{v.question}</IonLabel>
                    <IonSelect className="select-background" name={k} placeholder="Select an option" value={userData.user.M[`${k}` as keyof BinaryAddedTypes]?.S ?? 'N/A'}>
                      {
                        binaryAddedQuestions[`${k}` as keyof BinaryAddedTypes].map(((s: string, index: number) => <IonSelectOption key={`ind-${index}`} value={s}>{capitalizeAllFirstWords(s)}</IonSelectOption>))
                      }
                    </IonSelect>
                  </IonItem>
                );
              } else if(k === 'years' && v.on) {
                return (
                  <IonItem key={`${index}-${randomInt()}`}>
                    <IonLabel position="stacked">{v.question}</IonLabel>
                    <IonSelect className="select-background" placeholder="Select an option" value={parseInt(userData.user.M.years?.N ?? "1", 10) ?? 1} name={k}>
                      {
                        [1,2,3,4,5,6,7,8,9,10].map((o) => {
                          return <IonSelectOption key={`${index}-${randomInt()}`} value={o}>
                              {o === 10 ? '10+' : o}
                          </IonSelectOption>
                        })
                      }
                    </IonSelect>
                  </IonItem>
                )
              } else if(k === 'department' && v.on) {
                return (
                  <IonItem key={`${index}-${randomInt()}`}>
                    <IonLabel position="stacked">{v.question}</IonLabel>
                    <IonInput className="input-background" inputMode="text" type="text" name={k} placeholder={capitalizeAllFirstWords(k)} value={capitalizeAllFirstWords(userData.user.M.department?.S ?? 'No Department')} />
                  </IonItem>
                )
              }
            }
          }
        })
        }
      </aside>
    </section>
  )
}

export default AnswerQuestions;