import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonNote,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { useQuery } from "react-query";
import {
  MeetingAssistantContentTypes,
  MeetingAssistantErrorTypes,
} from "./interfaces/MeetingAssistant";
import { MeetingAssistantTypes } from "./MeetingAssistant";
import { useState } from "react";
import Avatar from "react-avatar";
import archetypes from "../data/archetypes.json";
import { useApiContext } from "../hooks/apiContext";
import { useAuthContext } from "../hooks/authContext";
import { ArchetypesTypes } from "./interfaces/archetypes";
import { isDatePastLimit } from "../utilities/dist/dateCheck";

const UserPersonalContent = ({ persona, orgcode }: MeetingAssistantTypes) => {
  const api = useApiContext();
  const auth = useAuthContext();

  const { isSuccess, isError, isFetching, isLoading, data } = useQuery<
    MeetingAssistantContentTypes | MeetingAssistantErrorTypes
  >(
    "personal-custom-data",
    async () => {
      return await api
        .getMeetingData(orgcode!, persona!, auth.jwt)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
    {
      retry: 2,
      enabled: !!orgcode,
      refetchOnWindowFocus: false,
    }
  );

  const [showMore, setShowMore] = useState(false);

  const [expanded] = useState<number | null>(null);

  if (isSuccess)
    return (
      <IonCard style={{
      }}>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                  }}
                >
                  <Avatar
                    src={`${archetypes[`${persona ?? ""}` as keyof ArchetypesTypes]
                      .image_url
                      }`}
                    size="70px"
                  />
                  <h3
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      fontSize: "2rem",
                    }}
                  >
                    {persona}
                    <span style={{ paddingLeft: "0.5rem" }}></span>
                  </h3>
                </div>
              </IonCol>
              <IonCol
                style={{
                  marginLeft: "10px",
                }}
              >
                <div>
                  <p>
                    {
                      archetypes[persona?.toString() as keyof ArchetypesTypes]
                        .summary
                    }
                  </p>
                  <div
                    style={{
                      display: showMore ? "block" : "none",
                    }}
                  >
                    <p>
                      {
                        archetypes[persona?.toString() as keyof ArchetypesTypes]
                          .meeting_notes
                      }
                    </p>
                    <h6
                      style={{
                        marginTop: "1rem",
                        fontWeight: "bold",
                        color: "#FBBF51",
                      }}
                    >
                      Description
                    </h6>
                    <ul>
                      {Object.entries(
                        archetypes[persona?.toString() as keyof ArchetypesTypes]
                          .description
                      ).map((item, index) => (
                        <li key={index}>
                          <span
                            style={{
                              textTransform: "capitalize",
                              fontWeight: "bold",
                            }}
                          >
                            {item[0]}:{" "}
                          </span>{" "}
                          <p>{item[1]}</p>
                        </li>
                      ))}
                    </ul>
                    {/* <h6 style={{
                      marginTop: "1rem",
                      fontWeight: "bold",
                      color: "#FBBF51",
                    }}>Chapter 2</h6> */}
                    {(data as MeetingAssistantContentTypes).data?.L.map(
                      (content, index) => {
                        if (
                          isDatePastLimit(
                            (data as MeetingAssistantContentTypes)
                              ?.contentDripDates?.L[index].S!,
                            parseInt(
                              content.M.launchDaysAfterConnection.N,
                              10
                            )
                          )
                        ) {
                          return content.M.content.M[`${(data as MeetingAssistantContentTypes).currentContentUse.S}`].S
                            .split(/\n\n/).map((value, index) => {
                              return value.match(/^(\w+\s*){1,4}:\s*(.*)$/) ?
                                <h6 style={{
                                  marginTop: "1rem",
                                  fontWeight: "bold",
                                  color: "#FBBF51",
                                }} key={`MeetingAssistantTitle-${value}`}>{value}</h6> : <p key={`MeetingAssistantContent-${index}`} >{value}</p>
                            })
                        }
                        return (
                          <p>No extra content available at the moment...</p>
                        );
                      }
                    )}
                  </div>
                  <IonButton
                    size="small"
                    onClick={() => setShowMore(!showMore)}
                    style={{
                      marginTop: "0.5rem"
                    }}
                  >
                    {showMore ? "Show Less" : "Show More"}
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          <div
            className={`accordion-content ${expanded === 0 ? "open" : ""}`}
          ></div>
        </IonCardContent>
      </IonCard>
    );
  else if (isError)
    return (
      <IonNote>
        There was an error loading your extra content. Please try again later.
      </IonNote>
    );
  else if (isLoading || isFetching) return <IonSpinner name="bubbles" />;
  else
    return (
      <IonNote>
        An unknown error has occurred. We apolgize for the inconvenience. Please
        try again later.
      </IonNote>
    );
};

export default UserPersonalContent;
