import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
// import { notificationsOutline } from 'ionicons/icons';
import UserDetailsModalBtn from './UserDetailsModalBtn';
import { SingleMemberTypes } from './interfaces/Dashboard';
import Avatar from 'react-avatar';
import '../style/EditToolbar.css';

interface EditToolbarTypes {
  userData: SingleMemberTypes
}

const EditToolbar = ({ userData }: EditToolbarTypes) => {
  const handleAlert = () => {
    alert('This feature is under development at the moment. We are continually building out Rivet to give you a better experience. Thank you for your understanding!');
  }

  return (
    <IonGrid className="satoshi">
      <IonRow>
        <IonCol sizeXl="2" sizeLg="2" sizeSm="2" sizeXs="2">
          {/* <IonButton title="Notifications" color="dark" fill="clear" onClick={handleAlert}>
            <IonIcon icon={notificationsOutline} />
          </IonButton> */}
        </IonCol>
        <IonCol sizeXl="2" sizeLg="2" sizeSm="3" sizeXs="3" className="small-col">
        </IonCol>
        <IonCol sizeXl="6" sizeLg="6" sizeSm="6" sizeXs="6">
          <UserDetailsModalBtn />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}

export default EditToolbar;