/** Styles Imports... */
import '../style/Login.css';

import { IonButton, IonInput, IonItem, IonLabel, IonList, IonToast, useIonAlert } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../hooks/authContext';
import { useCognitoContext } from '../hooks/cognitoContext';
import rivetLogoLight from '../resources/01_Logo_Black.svg';
import rivetLogoDark from '../resources/02_Logo_White.svg';
import { AuthAttributeTypes, AuthResultTypes } from '../services/cognito-service';

type SignInFormInputs = {
  email: string
  password: string
}

const Login = () => {
  const cognito = useCognitoContext();
  const userAuth = useAuthContext();

  const { control, handleSubmit, formState: { errors }, setError } = useForm<SignInFormInputs>({
    defaultValues: {}
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const errorMessage = useRef("Incorrect email or password, please try again.");
  const [mfaPresent] = useIonAlert();

  const onSubmit: SubmitHandler<SignInFormInputs> = async (data) => {
    try {
      setIsButtonDisabled(true);
      const result = await cognito.authenticate(data.email, data.password);
      if ((result as AuthResultTypes).authed) {
        userAuth.setJwt && userAuth.setJwt((result as AuthResultTypes).jwt);
        return userAuth.login && userAuth.login();
      }
      if ((result as AuthResultTypes).mfaOn) {
        mfaPresent({
          backdropDismiss: false,
          header: 'Multi-Factor Authentication',
          subHeader: 'You must enter the MFA code we sent you.',
          message: 'You should have received an email or text, according to your MFA preference.',
          cssClass: 'large-modal',
          inputs: [
            {
              name: 'MFACode',
              placeholder: 'Type code here...',
              type: 'text',
            }
          ],
          buttons: [
            {
              text: 'Cancel',
              handler: () => {
              }
            },
            {
              text: 'Submit',
              handler: async (data: { MFACode: string }) => {
                try {
                  await cognito.completeMfaChallenge(data.MFACode, result as AuthAttributeTypes);
                  return userAuth.login && userAuth.login();
                } catch (error) {
                  errorMessage.current = "Incorrect MFA Code, please try again."
                  setIsToastOpen(true);
                }
              }
            }
          ],
        });
        return setIsButtonDisabled(false);
      }
      /* Case: Temporary Password */
      mfaPresent({
        backdropDismiss: false,
        header: 'New Password',
        subHeader: 'You must change the given password to your own...',
        message: 'The password must, at minimum, be 8 characters, and must have at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter.',
        cssClass: 'large-modal',
        inputs: [
          {
            name: 'newPassword',
            placeholder: 'New Password',
            type: 'password',
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            handler: () => {
            }
          },
          {
            text: 'Submit',
            handler: async (data: { newPassword: string }) => {
              cognito.completePasswordChallenge(data.newPassword, result as AuthAttributeTypes)
                .then(() => {
                  alert('Password change successful 🥳');
                  userAuth.login && userAuth.login();
                  window.location.reload();
                }).catch(err => {
                  alert(`New password entry failed 🙀. ${err.message}`);
                  setIsButtonDisabled(false);
                });
            }
          }
        ],
      });
      return setIsButtonDisabled(false);
    } catch (error) {
      setError("email", {});
      setError("password", {});
      setIsToastOpen(true);
      setIsButtonDisabled(false);
    }
  }

  return (
    <section className="satoshi login-container">
      <IonToast
        isOpen={isToastOpen}
        message={errorMessage.current}
        onDidDismiss={() => setIsToastOpen(false)}
        duration={5000}
        position='top'
        icon={alertCircleOutline}
        buttons={[
          {
            text: 'Dismiss',
            role: 'cancel',
            handler: () => { },
          },
        ]}
      />
      <picture>
        <source srcSet={rivetLogoDark} media="(prefers-color-scheme:dark)" />
        <img className="menu-logo" src={rivetLogoLight} alt="Rivet" />
      </picture>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <h1 style={{
          marginLeft: "0.4rem",
        }}>Welcome Back</h1>
        <p style={{ marginLeft: "0.4rem", marginTop: "0" }}>Enter your Rivet account details</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonList mode="ios">
          <IonItem mode="ios" className={errors?.email && "ion-invalid ion-touched"}>
            <IonLabel mode="ios" position="stacked">Email</IonLabel>
            <Controller name='email' control={control} rules={{ required: true }}
              render={({ field }) => <IonInput onIonChange={field.onChange} className={errors?.email ? "ion-invalid ion-touched input-background" : "input-background"} type="email" required placeholder="Email" name="email" />}
            />
          </IonItem>
          <IonItem mode='ios' className={errors?.password && "ion-invalid ion-touched"}>
            <IonLabel mode="ios" position="stacked">Password</IonLabel>
            <Controller name='password' control={control} rules={{ required: true }}
              render={({ field }) => <IonInput onIonChange={field.onChange} className={errors?.password ? "ion-invalid ion-touched input-background" : "input-background"} type="password" required placeholder="Password" name="password" />}
            />
          </IonItem>
        </IonList>
        <Link style={{ marginLeft: '0.5rem', display: 'block', marginTop: '1rem' }} to="/NewPassword">Forgot Password?</Link>
        <IonButton style={{ marginLeft: '0.5rem', marginTop: '1rem', }} disabled={isButtonDisabled} mode="ios" type='submit'>{isButtonDisabled ? 'Connecting' : 'Log In'}</IonButton>
      </form>
    </section>
  );
}

export default Login;