import { IonItem, IonLabel, IonList, IonNote, IonSpinner, IonTitle } from '@ionic/react';
import { useState } from 'react';
import { MeetingAssistantContentTypes, MeetingAssistantErrorTypes } from './interfaces/MeetingAssistant';
import { useQuery } from 'react-query';
import { useApiContext } from '../hooks/apiContext';
import { useAuthContext } from '../hooks/authContext';
import randomInt from '../utilities/random-int';
import { isDatePastLimit } from '../utilities/dateCheck';

// use these to query the content table
interface MeetingAssistantTypes {
  persona: string | undefined
  orgcode: string | undefined
  dateConnected: string
}

const Activities = ( { persona, orgcode, dateConnected }: MeetingAssistantTypes) => {

  const api = useApiContext();
  const auth = useAuthContext();

  const { data, isError, isLoading, isFetching, isSuccess } = useQuery<MeetingAssistantContentTypes | MeetingAssistantErrorTypes>('meeting-data', async () => {
    return await api.getMeetingData(orgcode!, persona!, auth.jwt)
      .then(res => {
        return res;
      }).catch(err => {throw new Error(err)});
  }, {
    retry: 2,
    enabled: !!orgcode,
    refetchOnWindowFocus: false
  });

  const [expanded, setExpanded] = useState<number | null>(null);
  const handleAccordionClick = (ind: number) => {
    setExpanded(expanded === ind ? null : ind);
  }

  if(isSuccess && (data as MeetingAssistantErrorTypes).message) return (<aside className="ion-padding" slot="content" ><IonNote>{(data as MeetingAssistantErrorTypes).message}</IonNote></aside>);

  else if(isSuccess) return (
    <section>
      <aside slot="content">
        {/* Welcome to your meeting assistant! The information provided below is to assist you in your upcoming meetings that go beyond a first meeting.<br /><br />
        <IonNote><span role="img" aria-label="warning">⚠️ </span>If you do not see content below, You'll be notified once it's available.</IonNote> */}
        <hr />
        {((data as MeetingAssistantContentTypes).data.L.length > 0 ? (data as MeetingAssistantContentTypes).data.L.map((c, index) => {
          // console.log('now: ', Date.now());
          // console.log('launch: ', (new Date(c.M.dateToLaunch.S!.toUpperCase()).getTime())/(1000 * 3600 * 24));
          // console.log('dt: ', isDatePastLimit(dateConnected, parseInt(c.M.launchDaysAfterConnection.N, 10)));
          if(isDatePastLimit(dateConnected, parseInt(c.M.launchDaysAfterConnection.N, 10))) {
            return (
              <aside key={`${randomInt()}-${index}`}>
                  <aside slot="content" style={{textAlign: "center", whiteSpace: "pre-line"}}>
                    <h3><u>Meeting {c.M.meetingNumber.N}</u></h3>
                    <IonList mode="ios" lines="none">
                      {c.M.activities.L.map((a, a_index) => {
                        return (
                          <IonItem key={`${randomInt()}-${a_index}`} mode="ios">
                            {/* regex -> Checks if there is a colon within the first 40 characters of a string, if so, then we bold the text before the colon */}
                            <p><span role="img" aria-label="diamond">💎</span>&nbsp;<strong>{`${/^.{0,40}:/.test(a.S) ? (`${a.S.split(':')[0]}:`) : ''}`}</strong>{/^.{0,40}:/.test(a.S) ? a.S.split(':')[1] : a.S}</p>
                          </IonItem>
                        )
                      })}
                    </IonList>
                  </aside>
              </aside>
            );
          } else return undefined;
        }).filter(x => typeof x !== undefined) : <IonNote>Content unavailable at the moment.</IonNote>)}
      </aside>
    </section>
  );
  else if(isError) return (<IonNote>There was an error loading your material. Please try again later.</IonNote>);
  else if(isLoading || isFetching) return (<IonSpinner name="bubbles" />);
  else return (<IonNote>An unknown error has occurred. We apolgize for the inconvenience. Please try again later.</IonNote>);
}

export default Activities;