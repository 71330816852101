import { FC, useEffect, useLayoutEffect, useRef } from "react";
import {
  IonAccordionGroup,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
  IonText,
  useIonModal,
} from "@ionic/react";
// import "../style/ProfileModalBtn.css";
import {
  MemberConnectionTypes,
  MessageMemberTypes,
} from "./interfaces/Connections";
import { capitalizeFirstLetter } from "../utilities/capitalization";
import Avatar from "react-avatar";
import { ArchetypesTypes } from "./interfaces/archetypes";
import { personCircleSharp, helpCircleSharp } from "ionicons/icons";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { useApiContext } from "../hooks/apiContext";
import { useAuthContext } from "../hooks/authContext";
import { ChatTypesExtended } from "./interfaces/Messaging";
import { QuestionsToAnswerTypes } from "./UserConnections";
import ConnectionQuestions from "./ConnectionQuestions";
import ConnProfileTiles from "./ConnProfileTiles";

/* Styles */
import "../style/ConnectionProfileModal.css";
import randomInt from "../utilities/random-int";

const ModalBody: FC<{
  connection: MemberConnectionTypes;
  archetypes: ArchetypesTypes;
  onDismiss: () => void;
  viewed: boolean;
  questionsToAnswer: QuestionsToAnswerTypes[];
}> = ({ connection, archetypes, onDismiss, questionsToAnswer }) => {
  const api = useApiContext();
  const auth = useAuthContext();
  const queryClient = useQueryClient();
  const history = useHistory();
  const moveToMessages = useRef(false);

  const viewedConnection = async () => {
    await api.setViewed(
      connection.connectionItems.id.S,
      connection.connectionItems.matchDateTime.S,
      auth.jwt
    );
  };

  useLayoutEffect(() => {
    viewedConnection();
  }, []);

  useEffect(() => {
    return () => {
      if (moveToMessages.current) history.push("/Messages");
      moveToMessages.current = false;
    };
  }, [moveToMessages]);

  const sendToMessageUser = async () => {
    // check for and return chatId
    const chatId = await api.getChatId(
      connection.connectionDetails.id?.S!,
      auth.userDetails?.["custom:memberId"]!,
      auth.jwt!
    );

    if (!chatId?.S) {
      // save connection details to query cache
      queryClient.setQueryData<MessageMemberTypes>("message-user", () => {
        return {
          id: connection.connectionDetails.id,
          user: connection.connectionDetails.user,
          newChat: true,
        };
      });
      moveToMessages.current = true;
    } else {
      queryClient.setQueryData<ChatTypesExtended>("message-user", () => ({
        chatId,
        newChat: false,
        name: {
          S: `${connection.connectionDetails.user.M.fname.S} ${connection.connectionDetails.user.M.lname.S}`,
        },
        messageCount: { N: 0 },
        user: connection.connectionDetails.user,
      }));
      moveToMessages.current = true;
    }
    onDismiss();
  };

  return (
    <IonContent>
      <div className="ion-modal-container">
        <div className="ion-modal-header">
          <IonButton
            onClick={onDismiss}
            style={{ textTransform: "capitalize" }}
          >
            Close Profile
          </IonButton>
        </div>
        <div className="ion-modal-basic-information ion-margin-top">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            {connection.connectionDetails.user.M.profile_picture_url?.S ? (
              <Avatar
                style={{ fontFamily: "satoshi", fontWeight: "bold" }}
                src={connection.connectionDetails.user.M.profile_picture_url?.S}
                round
                size="60"
              />
            ) : (
              <Avatar
                style={{ fontFamily: "satoshi", fontWeight: "bold" }}
                name={`${connection.connectionDetails.user.M.fname.S} ${connection.connectionDetails.user.M.lname.S}`}
                round
                size="60"
              />
            )}
            <div>
              <h6 className="ion-modal-header ion-text-capitalize">
                {`${connection.connectionDetails.user.M.fname.S} ${connection.connectionDetails.user.M.lname.S}`}
              </h6>
              <IonText className="ion-persona-title">
                {connection.connectionDetails.archetype.S}
              </IonText>
            </div>
          </div>
        </div>
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeMd="12">
              <div>
                <div className="modal-connecting-header">
                  <h3>Start Connecting!</h3>
                  <p>
                    Message your new connection to start the conversation! Set
                    up a quick 5-10 minute Zoom call or meet for coffee!
                  </p>
                </div>
              </div>
              <div className="ion-modal-button-group">
                <IonButton
                  onClick={sendToMessageUser}
                  style={{ textTransform: "capitalize" }}
                >
                  Message via Web App
                </IonButton>
                <IonButton
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textTransform: "capitalize" }}
                  href={`mailto:${
                    connection.connectionDetails.user.M.email.S
                  }?subject=Let's Connect!&body=Hi ${capitalizeFirstLetter(
                    connection.connectionDetails.user.M.fname.S!
                  )},%0D%0A %0D%0AIt would be great if we connected. Are you available for coffee or a zoom call next week?%0D%0A %0D%0A`}
                >
                  Email {connection.connectionDetails.user.M.fname.S!}
                </IonButton>
                <IonButton
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textTransform: "capitalize" }}
                  href={`tel:${connection.connectionDetails.user.M.phone_number?.S}`}
                >
                  Call {connection.connectionDetails.user.M.fname.S!}
                </IonButton>
              </div>
            </IonCol>
            <IonCol size="12">
              <div>
                <div className="modal-connecting-subheader">
                  <h4>Contact Details</h4>
                  <ul>
                    <li>
                      Phone: {""}
                      {connection.connectionDetails.user.M.phone_number?.S ||
                        "Not Available"}
                    </li>
                    <li>
                      Email: {""}{" "}
                      {connection.connectionDetails.user.M.email.S ||
                        "Not Available"}
                    </li>
                  </ul>
                </div>
              </div>
            </IonCol>
            <IonCol size="12">
              <IonAccordionGroup>
                <ConnProfileTiles
                  showMeetingPrompt={true}
                  paragraph={
                    <p>
                      {
                        archetypes[
                          `${
                            connection.connectionDetails.archetype
                              ?.S! as keyof ArchetypesTypes
                          }`
                        ].meeting_notes
                      }
                    </p>
                  }
                  connectionQuestionsEl={
                    questionsToAnswer.length > 0 ? (
                      <ConnectionQuestions
                        dismissParent={onDismiss}
                        questionsObject={questionsToAnswer}
                        connectionId={connection.connectionItems.id.S!}
                        matchDateTime={
                          connection.connectionItems.matchDateTime.S!
                        }
                      />
                    ) : undefined
                  }
                  oc={connection.connectionItems.members.M.member2.M.orgcode.S}
                  archetype={connection.connectionDetails.archetype.S}
                  dateConnected={connection.connectionItems.matchDateTime.S!}
                />
              </IonAccordionGroup>
            </IonCol>
            <IonCol size="12" sizeMd="">
              {connection.connectionItems.feathers?.L.length > 0 && (
                <IonRow>
                  <IonCol>
                    <aside className="row-container">
                      <h1>Feathers:</h1>
                      <IonList>
                        {connection.connectionItems.feathers?.L.map(
                          (f, index) => {
                            return (
                              <IonItem key={`${index}-${randomInt()}`}>
                                {f.S}
                              </IonItem>
                            );
                          }
                        )}
                      </IonList>
                    </aside>
                  </IonCol>
                </IonRow>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonContent>
  );
};

interface ProfileModalBtnTypes {
  connection: MemberConnectionTypes;
  archetypes: ArchetypesTypes;
  questionsToAnswer: QuestionsToAnswerTypes[];
}

const ProfileModalBtn = ({
  connection,
  archetypes,
  questionsToAnswer,
}: ProfileModalBtnTypes) => {
  const handleDismiss = () => {
    dismiss();
  };

  const [present, dismiss] = useIonModal(ModalBody, {
    onDismiss: handleDismiss,
    connection,
    archetypes,
    questionsToAnswer,
  });

  return (
    <IonButton fill="clear" color="dark" size="large" onClick={() => present()}>
      <IonIcon
        slot="start"
        className={questionsToAnswer.length > 0 ? "shiny" : ""}
        icon={
          questionsToAnswer.length > 0 ? helpCircleSharp : personCircleSharp
        }
      />{" "}
      View
    </IonButton>
  );
};

export default ProfileModalBtn;
