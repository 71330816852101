import { IonButton, IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonNote } from "@ionic/react";
import { cogSharp, logOut, mailSharp, peopleCircleSharp, peopleSharp, personCircleSharp, ribbonOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useLocation } from "react-router";
import { OrganizationContext } from "../OrganizationContext";
import { useAuthContext } from "../hooks/authContext";
import { useChatContext } from "../hooks/chatContext";
import rivetLogoLight from '../resources/01_Logo_Black.svg';
import rivetLogoDark from '../resources/02_Logo_White.svg';
import randomInt from "../utilities/random-int";

import '../style/Menu.css';

interface Route {
    url: string;
    order: number;
    iosIcon: string;
    mdIcon: string;
    title: string;
    isAvailable?: boolean;
}

const defaultRoutes: Route[] = [
    { title: 'Persona', url: '/Persona', order: 1, iosIcon: personCircleSharp, mdIcon: personCircleSharp, isAvailable: true },
    { title: 'Connections', url: '/Connections', order: 2, iosIcon: peopleCircleSharp, mdIcon: peopleCircleSharp, isAvailable: true },
    { title: 'Messages', url: '/Messages', order: 3, iosIcon: mailSharp, mdIcon: mailSharp, isAvailable: true },
    { title: 'Activities', url: '/Activities', order: 5, iosIcon: ribbonOutline, mdIcon: ribbonOutline, isAvailable: true },
    { title: 'Settings', url: '/Settings', order: 6, iosIcon: cogSharp, mdIcon: cogSharp, isAvailable: true }
];

const NavSideMenu = () => {
    const { selectedOrg } = useContext(OrganizationContext);
    const userAuth = useAuthContext();
    const location = useLocation();
    const chat = useChatContext();

    const [routes, setRoutes] = useState([
        ...[...defaultRoutes, { title: 'Community', url: '/Community', order: 4, iosIcon: peopleSharp, mdIcon: peopleSharp, isAvailable: selectedOrg?.isUsersOpen?.BOOL }]
            .sort((a, b) => a.order - b.order),
    ]);

    useEffect(() => {
        setRoutes([
            ...[...defaultRoutes, { title: 'Community', url: '/Community', order: 4, iosIcon: peopleSharp, mdIcon: peopleSharp, isAvailable: selectedOrg?.isUsersOpen?.BOOL }]
                .sort((a, b) => a.order - b.order),
        ]);
    }, [selectedOrg]);

    return (
        <IonMenu contentId="main-content">
            <IonContent className="ion-padding satoshi">
                <IonList>
                    <picture>
                        <source srcSet={rivetLogoDark} media="(prefers-color-scheme:dark)" />
                        <img className="menu-logo" src={rivetLogoLight} alt="Rivet" />
                    </picture>
                    <IonNote>
                        Spark new connections.
                        <br />
                        Create more community.
                    </IonNote>
                    {routes.map((route, index) => {
                        return (
                            <IonMenuToggle key={`${index}-${randomInt()}`} autoHide={false}>
                                {
                                    route.isAvailable ?
                                        <IonItem className={location.pathname === route.url ? 'selected' : ''} routerLink={route.url} routerDirection="none" lines="none" detail={false}>
                                            <IonIcon slot="start" ios={route.iosIcon} md={route.mdIcon} />
                                            <IonLabel>{route.title} {(route.title === 'Messages' && chat.chatCount > 0) && <Avatar size="20" round value="!" />} </IonLabel>
                                        </IonItem> :
                                        <IonItem className={location.pathname === route.url ? 'selected' : 'disabled'} routerLink={route.url} routerDirection="none" lines="none" detail={false}>
                                            <IonIcon slot="start" ios={route.iosIcon} md={route.mdIcon} />
                                            <IonLabel>{route.title} {(route.title === 'Messages' && chat.chatCount > 0) && <Avatar size="20" round value="!" />} </IonLabel>
                                        </IonItem>
                                }
                            </IonMenuToggle>
                        );
                    })}
                    {
                        userAuth.auth && <IonButton color="primary" className="ion-margin-top" expand="full" onClick={() => {
                            userAuth?.logout && userAuth.logout();
                            localStorage.clear();
                        }}>
                            <IonIcon slot="start" icon={logOut} />
                            Logout
                        </IonButton>
                    }
                </IonList>
            </IonContent>
        </IonMenu >
    );
};

export default NavSideMenu;
