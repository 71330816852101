import { IonTitle } from '@ionic/react';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useAuthContext } from '../../hooks/authContext';
import '../../style/Activities.css';
import MagicQuestionsDropdown from '../MagicQuestionsDropwdown';

const Activities = () => {

  const history = useHistory();
  const auth = useAuthContext();
  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

  useLayoutEffect(() => {
    if (!auth.auth) history.push('/');
  }, [auth.auth, history]);

  useEffect(() => {
    if (!accordionGroup.current) {
      return;
    }
    accordionGroup.current.value = ['questionsItem'];
  }, []);

  return (
    <section className='activities-container'>
      <IonTitle title="Activities" className="activities-title">Activities</IonTitle>
      <MagicQuestionsDropdown />
    </section>
  );
}

export default Activities;