import { createContext, useState, FC, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useCognitoContext } from './hooks/cognitoContext';
import { useHistory } from 'react-router';
import { AuthResultTypes } from './services/cognito-service';

export interface AuthContextTypes {
  auth: boolean
  jwt: string | undefined
  userDetails: UserAttributeTypes | undefined
  logout?: () => void
  login?: () => void
  setJwt?: (returnedJwt: string) => void
}

interface StringTypes {
  S?: string
}

interface OrgcodeTypes {
  orgcode_org: StringTypes
  orgtier: StringTypes
}

interface CognitoAttributeTypes {
  Name: string
  Value: string
}

export interface UserAttributeTypes {
  'custom:organization': string
  'custom:memberId': string
  'sub': string
  'email_verified': string
  'phone_number_verified': string
  'phone_number': string
  'given_name': string
  'family_name': string
  'email': string
}

export const AuthContext = createContext<AuthContextTypes>({auth: false, jwt: undefined, userDetails: undefined});

const AuthProvider: FC = ({children}) => {
  const cognito = useCognitoContext();
  const history = useHistory();
  const location = useLocation();
  const [auth, setUser] = useState(false);
  // console.log('location: ', JSON.stringify(location));
  const jwtRef = useRef<string | undefined>(undefined);
  const userRef = useRef({} as UserAttributeTypes);

  const getAttributes = async () => {
    await cognito.getUserAttrs()
      .then(res => {
        userRef.current = (res as CognitoAttributeTypes[]).reduce((prev, curr) => ({...prev, [`${curr.Name}`]: curr.Value}), {}) as UserAttributeTypes;
        setUser(true);
        if(['/', '/Login'].includes(location.pathname)) history.push('/Persona');
        else history.push(location.pathname); // this works, but when they login, that's the problem because it reloads the component but keeps the path at /Login
      }).catch(err => console.error('user attributes error: ', err));
  }
  
  const login = async () => {
    await checkAuth();
  }

  const logout = async () => {
    await cognito.logout();
    setUser(false);
    window.location.reload();
  }

  const setJwt = (returnedJwt: string) => {
    jwtRef.current = returnedJwt;
  }

  const checkAuth = async () => {
    await cognito.isUserAuthenticated()
      .then(async res => {
        if((res as AuthResultTypes).authed) {
          setJwt((res as AuthResultTypes).jwt);
          await getAttributes();
        }
    }).catch( e => console.log('Possible connection error. ', e));
  }


  useLayoutEffect( () => {
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{auth, jwt: jwtRef.current, userDetails: userRef.current, logout, login, setJwt}}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;