import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { useState, MouseEvent, useRef, ChangeEvent } from 'react';
import { happyOutline, sendSharp } from 'ionicons/icons';
import Picker, { IEmojiData } from 'emoji-picker-react';
import { useQueryClient } from 'react-query';
import { useAuthContext } from '../../hooks/authContext';
import { useChatContext } from '../../hooks/chatContext';
import { MessageMemberTypes } from '../interfaces/Connections';
import { ChatTypesExtended, SingleChatTypes } from '../interfaces/Messaging';
import { v4 as uuidv4 } from 'uuid';
import '../../style/MessagingModal.css';
import { useMediaQuery } from '../../hooks/useMediaQuery';

interface SendMessageTypes {
  currentChatDetails?: ChatTypesExtended
  newChatDetails?: MessageMemberTypes
}

const SendMessage = ({ currentChatDetails, newChatDetails }: SendMessageTypes) => {

  const chat = useChatContext();
  const auth = useAuthContext();
  const queryClient = useQueryClient();
  const isMobile = useMediaQuery("(max-width: 767px)")

  const [messageInput, setMessageInput] = useState<string | number | readonly string[] | undefined>('');
  const chosenEmoji = useRef<IEmojiData | null>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const clearInput = () => {
    setMessageInput('');
  }

  const showHideEmojiPicker = () => {
    setShowEmojiPicker(b => !b);
  }

  const onEmojiClick = (event: MouseEvent<Element>, emojiObject: IEmojiData) => {
    chosenEmoji.current = emojiObject;
    showHideEmojiPicker();
    insertEmoji();
  };

  const insertEmoji = () => {
    // grab caret position
    const caretPos = inputRef.current?.selectionStart;
    // slice into value
    const updatedValue = `${inputRef.current?.value.slice(0, caretPos as number)}${chosenEmoji.current?.emoji}${inputRef.current?.value.slice(caretPos as number)}`;
    setMessageInput(updatedValue);
  }

  const onSubmit = () => {
    if (!messageInput) return;
    else if ((messageInput as string).length > 280) {
      alert('Exceeded max length of 280 characters for your message.');
      return;
    }
    // append id and timestamp in backend
    const messageObject = {
      message: messageInput,
      sender: `${auth.userDetails?.given_name} ${auth.userDetails?.family_name}`
    }

    // userId is id of user that originally sent the message (recipient, in this case)
    chat.sendMessage && chat.sendMessage(currentChatDetails?.userId?.S ?? newChatDetails?.id?.S, currentChatDetails?.chatId?.S ?? chat.currentOpenChatId.current, messageObject, newChatDetails?.user ?? currentChatDetails?.user);

    // modify messages with id 'message-data', then clear input
    queryClient.setQueryData('message-data', (oldData: any) => {
      const setMessageObject = {
        M: {
          id: { S: uuidv4() },
          message: { S: messageInput },
          sender: { S: `${auth.userDetails?.given_name} ${auth.userDetails?.family_name}` },
          timestamp: { S: new Date().toISOString() }
        }
      }
      if (oldData) {
        return [...oldData as SingleChatTypes[], setMessageObject]
      } else {
        return [setMessageObject]
      }
    });
    clearInput();
  }

  return (
    <footer style={{ marginBottom: "2rem" }} className="sticky-bottom chat-list-background">
      <IonGrid>
        <IonRow>
          <IonCol>
            {showEmojiPicker &&
              <Picker onEmojiClick={onEmojiClick} />}
          </IonCol>
        </IonRow>
        <IonRow style={{ textAlign: "center" }} className="ion-align-items-baseline">
          <IonCol size={isMobile ? "2" : "1"}>
            <IonButton fill="clear" title="Emoji :D" color="dark" size="small" onClick={showHideEmojiPicker}>
              <IonIcon slot="icon-only" icon={happyOutline} />
            </IonButton>
          </IonCol>
          <IonCol size={isMobile ? "8" : "9"}>
            <input ref={inputRef} style={{ margin: "0 1rem" }} placeholder="Type message here..." value={messageInput} onChange={(e: ChangeEvent<HTMLInputElement>) => { setMessageInput(e.currentTarget.value?.toString()) }} spellCheck type="text" maxLength={280} />
          </IonCol>
          <IonCol size={isMobile ? "2" : "1"}>
            <IonButton title="Send" fill="clear" color="dark" size="small" onClick={onSubmit}>
              <IonIcon slot="icon-only" icon={sendSharp} />
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </footer>
  );
}

export default SendMessage;