import { FC, useEffect, useRef, useState } from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonList, IonLoading, IonRow, IonTitle } from '@ionic/react';
import { ChatTypesExtended, SingleMapChatTypes } from '../interfaces/Messaging';
import { useQuery, useQueryClient } from 'react-query';
import { useChatContext } from '../../hooks/chatContext';
import { useApiContext } from '../../hooks/apiContext';
import { useAuthContext } from '../../hooks/authContext';
import SendMessage from './SendMessage';
import { MessageMemberTypes } from '../interfaces/Connections';
import S3List from './S3List';
import Avatar from 'react-avatar';
import { useDebouncedCallback } from 'use-debounce';
import '../../style/MessagingModal.css';
import randomInt from '../../utilities/random-int';

const MessagingModalBody: FC<{
  messageDetails: ChatTypesExtended | MessageMemberTypes
  onDismiss: () => void
}> = ({ messageDetails, onDismiss }) => {
  const api = useApiContext();
  const auth = useAuthContext();
  const queryClient = useQueryClient();
  const chat = useChatContext();

  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [renderS3, setRenderS3] = useState(false);

  const exp = useRef<RegExp>(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const debounced = useDebouncedCallback(() => contentRef.current?.scrollToBottom(), 0);

  useEffect(() => {
    debounced();
  });

  const loadMoreData = () => {
    // load s3
    setRenderS3(true);
    // then turn off, because it's only to activate s3List
    setInfiniteDisabled(true);
  }

  const refetchify = async () => {
    queryClient.setQueryData<number>('marker', (previousMarker) => previousMarker! - 1);
    queryClient.refetchQueries('s3-messages');
  }

  const getHTMLLinks = (str: string) => {
    const splitString = str.split(/[\s]/g);
    return <p>{splitString.map((s, index) => { const cs = s.replace(/[,.?'";:)-=+!]$/ig, ""); return exp.current.test(cs) ? <a key={`${index}-${randomInt()}`} style={{ textDecoration: "none" }} href={cs}>{s} </a> : `${s} ` })}</p>
  }

  const { data, isSuccess, isError, isLoading } = useQuery<SingleMapChatTypes[]>('message-data', async () => {
    chat.currentOpenChatId.current = (messageDetails as ChatTypesExtended).chatId?.S;
    return await api.getChat((messageDetails as ChatTypesExtended).chatId?.S ?? '', auth.jwt).then(res => res).catch(e => { throw new Error(e); })
  }, {
    retry: 2,
    refetchOnWindowFocus: false
  });

  const dismissModal = () => {
    chat.currentOpenChatId.current = undefined;
    console.log("Closing Modal...")
    onDismiss();
  }

  if (isLoading) return <IonLoading isOpen spinner="circular" />;
  else if (isError) return <aside>There was an error. Please try again, later...</aside>
  else if (!(messageDetails as ChatTypesExtended).newChat && isSuccess && (data && data.length > 0)) return (
    <>
      <IonContent className="satoshi" ref={contentRef}>
        <IonGrid>
          <IonRow className="sticky-top chat-list-background ion-align-items-baseline">
            <IonCol size="10">
              <Avatar style={{ display: "inline-block" }} name={(messageDetails as ChatTypesExtended).name.S} size="30" round /> <h3 style={{ textTransform: "capitalize", display: "inline-block", marginLeft: "0.5rem" }}>{(messageDetails as ChatTypesExtended).name.S}</h3>
            </IonCol>
            <IonCol size="2">
              <IonButton fill="clear" color="dark" onClick={dismissModal}>X</IonButton>
            </IonCol>
            <br />
            <hr />
          </IonRow>
          <IonRow>
            <IonCol>
              <IonInfiniteScroll
                onIonInfinite={loadMoreData}
                threshold="10px"
                disabled={isInfiniteDisabled}
                position="top"
              >
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="Loading more data..."
                >
                </IonInfiniteScrollContent>
              </IonInfiniteScroll>
              <IonList className="chat-list-background">
                {(data && data.length > 15) &&
                  <IonButton fill="clear" color="dark" size="small" onClick={refetchify}>fetch more</IonButton>
                }
                {(data && data.length < 20 || renderS3) &&
                  <S3List sEmail={auth.userDetails?.email} rEmail={(messageDetails as ChatTypesExtended).user.M.email.S} jwt={auth.jwt} />
                }
                {data ? data?.map(({ M }) => {
                  return (
                    <IonItem key={`${M.id.S}-${randomInt()}`} className="chat">
                      <p style={{ textTransform: "capitalize", fontWeight: "bold" }}>{M.sender.S?.split(' ')[0]}:&nbsp;</p>
                      {getHTMLLinks(M.message.S as string)}
                    </IonItem>
                  );
                }) :
                  <IonItem>Let's get to connecting!</IonItem>
                }
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <SendMessage currentChatDetails={(messageDetails as ChatTypesExtended)} />
    </>
  );
  else if ((messageDetails as MessageMemberTypes).newChat) return (
    <>
      <IonContent className="satoshi" ref={contentRef}>
        <IonGrid>
          <IonRow className="sticky-top chat-list-background ion-align-items-baseline">
            <IonCol size="10">
              <Avatar style={{ display: "inline-block" }} name={`${(messageDetails as MessageMemberTypes).user.M.fname.S} ${(messageDetails as MessageMemberTypes).user.M.lname.S}`} size="30" round /> <IonTitle style={{ textTransform: "capitalize", display: "inline-block", marginLeft: "0.5rem" }}>{`${(messageDetails as MessageMemberTypes).user.M.fname.S} ${(messageDetails as MessageMemberTypes).user.M.lname.S}`}</IonTitle>
            </IonCol>
            <IonCol size="2">
              <IonButton fill="clear" color="dark" onClick={onDismiss}>X</IonButton>
            </IonCol>
            <br />
            <hr />
          </IonRow>
          <IonRow>
            <IonCol>
              <IonList className="chat-list-background">
                {data ? data?.map(({ M }) => {
                  return (
                    <IonItem key={`${M.id.S}-${randomInt()}`} className="chat">
                      <p style={{ textTransform: "capitalize", fontWeight: "bold" }}>{M.sender.S?.split(' ')[0]}:&nbsp;</p>
                      <p>{M.message.S}</p>
                    </IonItem>
                  )
                }) :
                  <IonItem>Let's get to connecting!</IonItem>
                }
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <SendMessage newChatDetails={(messageDetails as MessageMemberTypes)} />
    </>
  );
  else return (
    <aside>Currently no items available...</aside>
  );
}

export default MessagingModalBody;