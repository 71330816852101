import { ReactNode, createContext, useState } from "react";
import { DynamoBooleanType, DynamoStringTypes } from "./components/interfaces/dynamodb";

export interface Organization {
    orgname: DynamoStringTypes
    orgcode: DynamoStringTypes,
    isUsersOpen?: DynamoBooleanType
}

export interface OrganizationContextValue {
    list: Organization[];
    selectedOrg: Organization | null;
    setList: (value: Organization[]) => void
    setSelectedOrg: (value: Organization) => void
}

export const OrganizationContext = createContext<OrganizationContextValue>({
    list: [],
    setSelectedOrg: (value: Organization | null) => { },
    setList: (value: Organization[]) => { },
    selectedOrg: null
});

export interface Props {
    children: ReactNode
}

export const OrganizationProvider = ({ children }: Props) => {
    const [list, setList] = useState<Organization[]>([]);
    const [selectedOrg, setSelectedOrg] = useState<Organization>(JSON.parse(localStorage.getItem("selectedOrg") || '{"orgcode":{"S":""},"orgname":{"S":""},"isUsersOpen":{"BOOL":false}}'));

    function handleNewSelection(value: Organization) {
        setSelectedOrg(value)
        localStorage.setItem("selectedOrg", JSON.stringify(value));
    }

    return (
        <OrganizationContext.Provider value={{ list, setList, selectedOrg, setSelectedOrg: handleNewSelection, }}>
            {children}
        </OrganizationContext.Provider>
    )
}