import { FC, useState } from 'react';
import { IonButton, IonContent, useIonModal } from '@ionic/react';
import Avatar from 'react-avatar';
import { useApiContext } from '../hooks/apiContext';
import { useAuthContext } from '../hooks/authContext';
import { useQueryClient } from 'react-query';

import '../style/ProfilePictureModal.css';


const ModalBody: FC<{
    onDismiss: () => void,
    profile_picture_url?: string,
    first_name?: string,
    last_name?: string,
    onImageChange: (file: File | null) => void
}> = ({ onDismiss, profile_picture_url, onImageChange }) => {

    const api = useApiContext();
    const auth = useAuthContext();
    const queryClient = useQueryClient();

    const [previewUrl, setPreviewUrl] = useState(profile_picture_url);
    const [hasNewImage, setHasNewImage] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false)

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            setPreviewUrl(URL.createObjectURL(file));
            setSelectedFile(file);
            setHasNewImage(true);
            onImageChange(file);
        }
    };

    const handleConfirm = async () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("userEmail", auth.userDetails!.email)
            try {
                setIsLoading(() => true);
                await api.uploadProfilePicture(formData, auth.jwt)
                setHasNewImage(false);
                queryClient.fetchQuery('userData')
                setIsLoading(() => false);
                onDismiss();
            } catch (error) {
                setIsLoading(() => false);
                console.error("Error al subir la imagen:", error);
            }
        }
        setIsLoading(() => false);
    };

    return (
        <IonContent>
            <IonButton className="ion-close-button" onClick={onDismiss}>Close</IonButton>
            <div>
                <h2 className="modal-header">Change Your Profile Picture</h2>
                <div className="modal-content">
                    {
                        previewUrl
                            ? <Avatar src={previewUrl} fgColor="black" color="#C7B8F5" round size="225" />
                            : <Avatar src={'https://ionicframework.com/docs/img/demos/avatar.svg'} fgColor="black" color="#C7B8F5" round size="200" />
                    }
                    <div style={{
                        maxWidth: "20rem"
                    }}>
                        <p style={{
                            textAlign: "center"
                        }}>Use a square image (1:1 ratio) for the best profile picture display.
                        </p>
                    </div>
                    <div className="file-upload-container">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            id="file-upload"
                            className="file-upload-input"
                        />
                        <label htmlFor="file-upload" className="file-upload-label">
                            Select New Picture
                        </label>
                    </div>
                    <IonButton
                        className="confirm-button"
                        onClick={handleConfirm}
                        disabled={!hasNewImage || isLoading}
                    >
                        {isLoading ? "Uploading..." : "Confirm"}
                    </IonButton>
                </div>
            </div>
        </IonContent>
    );
};

interface ProfileModalBtnTypes {
    onDismiss: () => void;
    profile_picture_url?: string;
    first_name?: string;
    last_name?: string;
}

const ProfilePictureModal = ({ profile_picture_url, first_name, last_name }: ProfileModalBtnTypes) => {
    const handleDismiss = () => {
        dismiss();
    }

    const handleImageChange = (file: File | null) => {
        console.log('Archivo seleccionado:', file);
    }

    const [present, dismiss] = useIonModal(ModalBody, {
        onDismiss: handleDismiss,
        profile_picture_url,
        first_name,
        last_name,
        onImageChange: handleImageChange
    });

    return (
        <>
            {
                profile_picture_url
                    ? <Avatar onClick={() => present({ backdropDismiss: false })} className="avatar" src={profile_picture_url} fgColor="black" color="#C7B8F5" round size="80" />
                    : <Avatar onClick={() => present({ backdropDismiss: false })} className="avatar" name={`${first_name} ${last_name}`} fgColor="black" color="#C7B8F5" round size="80" />
            }
        </>
    );
};

export default ProfilePictureModal;
