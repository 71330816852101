import { Reducer } from 'react';
import { ChatActionKind, ChatActionTypes, DynamoMapStringTypes } from '../interfaces/Messaging';


export const chatReducer: Reducer<DynamoMapStringTypes[], ChatActionTypes> = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case ChatActionKind.UPDATE:
      return [
        ...state,
        ...payload
      ].filter((v,i,a)=>a.findIndex(({M})=>(M.chatId?.S===v.M.chatId?.S))===i); // remove dupes
    case ChatActionKind.NO_CHANGE:
      return state;  
    default:
      return state;
  }
}