"use strict";
exports.__esModule = true;
exports.isDatePastLimit = void 0;
exports.isDatePastLimit = function (date, limit) {
    // console.log('date: ', date, typeof date);
    // console.log('limit: ', limit, typeof limit);
    var date1 = new Date(date.toUpperCase());
    var date2 = Date.now();
    var diffTime = Math.abs(date2 - date1.getTime());
    var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // console.log('diffDays: ', diffDays);
    if (diffDays > limit) {
        // console.log("The datetime has surpassed the limit.");
        return true;
    }
    else {
        // console.log("The datetime has not surpassed the limit.");
        return false;
    }
};
