import { FocusEvent, lazy, Suspense, useRef, useState } from 'react';
import { IonList, IonItem, IonLabel, IonInput, IonButton, IonModal, IonToast, IonTitle, IonNote, IonSpinner } from '@ionic/react';
import { closeSharp, informationCircleSharp } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import { useCognitoContext } from '../hooks/cognitoContext';
import '../style/Login.css';
// const NoFinishSignUp = lazy(() => import('./NoFinishSignUp'));

interface NewPasswordTypes {
  email: string | number | null | undefined
  verificationCode: string | number | null | undefined
  newPassword: string | number | null | undefined
  newPasswordVerify: string | number | null | undefined
}

const NewPassword = () => {

  const history = useHistory();
  const cognito = useCognitoContext();
  const [showDialog, setShowDialog] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showEmailButton, setShowEmailButton] = useState(false);
  const credentials = useRef({} as NewPasswordTypes);

  const handleInput = (e: FocusEvent<HTMLIonInputElement> | undefined) => {
    e?.preventDefault();
    if(e && credentials.current) credentials.current[`${e?.target.name}` as keyof NewPasswordTypes] = (e?.target.value as string).trim();
    // console.log('data: ', credentials.current, e?.target.name, e?.target.value);
  }

  const handlePasswordRequest = async () => {
    // get creds
    const unauthCreds = await cognito.getCredentials(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_UNAUTH_GUEST! : '/v1/get-unauth-creds').then(res => res).catch(err => { throw err });
    await cognito.forgotPassword(process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_COGNITO_AUTH! : '/v1/cognito-auth', credentials.current.email as string, JSON.parse(unauthCreds?.body!))
      .then(res => {
        if(res.passwordReset === true) {
          alert('It looks like you did\'t complete your password process. No worries, we just sent you a temporary password to get it done 😊');
        } else {
          setShowDialog(true);
        }
      }).catch(err => {
        console.error('err: ', err);
        if (err.legacyRegister === true) {
          if(window.confirm(err.message)) {
            history.push('/Register');
          } else {
            alert('We recommend you use the Rivet assessment link that your admin provided via email. Please connect with them if you have misplaced it.');
          }
        } else {
          alert(`There was an error. Please try again later.`);
        }
      });
  }

  const handleNewPassword = async () => {
    if(credentials.current.newPassword !== credentials.current.newPasswordVerify) {
      alert('Sorry but the passwords aren\'t matching...please make sure the new password matches the verify password.');
      return;
    }
    await cognito.confirmPassword(credentials.current)
      .then(res => {
        console.log('new: ', res);
        setShowToast(true);
      }).catch((err: Error) => {
        console.error('new err: ', err);
        alert(err.message);
      });
  }

  const handleDialogClose = () => {
    setShowDialog(false);
  }

  const handleToastClose = () => {
    setShowToast(false);
    history.push('/Login');
    window.location.reload();
  }

  // const emailButton = showEmailButton ? 
  //   (<NoFinishSignUp />) 
  //   :
  //   (<IonButton onClick={() => setShowEmailButton(true)} mode="ios" style={{margin: '1.2rem'}}>Show Email Button</IonButton>);

  return (
    <section className="satoshi login-container">
      <h1>Forgot your password? <span role="img" aria-label="confused-face">😵‍💫</span></h1>
      <br />
      <IonList mode="ios">
        <IonItem>
          <IonLabel mode="ios" position="stacked">What's your email address?</IonLabel>
          <IonInput name="email" type="email" placeholder="Email" onBlur={handleInput} />
        </IonItem>
        <hr />
        <IonButton mode="ios" style={{margin: '1.2rem'}} onClick={handlePasswordRequest} type="button">Request New Password</IonButton>
      </IonList>
      {showDialog && (
        <IonModal mode="ios" isOpen={showDialog} onDidDismiss={handleDialogClose} backdropDismiss={false}>
          <IonTitle>Please enter your confirmation credentials below:</IonTitle>
          <p style={{paddingLeft: '1.2rem'}}>* You should have recieved an email with your verification code. <strong>Please check your email.</strong></p>
          <p style={{paddingLeft: '1.2rem', fontWeight: 'bold'}}><em>If you have not recieved an email, you may have used the the wrong email.</em></p>
          <IonNote style={{padding: '1.2rem', backgroundColor: 'yellow', fontWeight: 'bold'}}>The password must, at minimum, be 8 characters, and must have at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter.</IonNote>
          <hr />
          <IonList mode="ios">
            <IonItem>
              <IonLabel>Verification Code</IonLabel>
              <IonInput name="verificationCode" type="password" placeholder="Verification Code" onBlur={handleInput} />
            </IonItem>
            <IonItem>
              <IonLabel>New Password</IonLabel>
              <IonInput name="newPassword" type="password" placeholder="New Password" onBlur={handleInput} />
            </IonItem>
            <IonItem>
              <IonLabel>Verify Password</IonLabel>
              <IonInput name="newPasswordVerify" type="password" placeholder="Verify Password" onBlur={handleInput} />
            </IonItem>
            <hr />
            <IonButton style={{margin: '1.2rem'}} onClick={handleNewPassword} type="button">Submit</IonButton>
          </IonList>
        </IonModal>
      )}
      {showToast && (
        <IonToast
          isOpen={showToast}
          message="New password is set!"
          icon={informationCircleSharp}
          position="middle"
          cssClass="toast-style"
          buttons={[{
            side: 'end',
            icon: closeSharp,
            text: 'Close',
            handler: () => {
              handleToastClose();
            }
          }]}
        />
      )}
      <hr />
      {/* <aside>
        <h1>Or did you forget to finish the sign up process? Meaning you received the temporary password email, but never used it. <span role="img" aria-label="thinking-face">🤔</span></h1>
        <p>Just send us an email and we'll send you a new temporary password within the hour.</p>
        <Suspense fallback= { <IonSpinner /> }>
          {emailButton}
        </Suspense>
        <IonNote>Please note that you must send us the email that you originally used. Please be sure that you are sending us the correct email. Thank you!</IonNote>
      </aside> */}
    </section>
  )
}

export default NewPassword;