/** Styles.. */
import "../style/MatchesPage.css";

import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonLabel,
  IonNote,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSkeletonText,
  IonText,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useQuery } from "react-query";
import { useApiContext } from "../hooks/apiContext";
import { useAuthContext } from "../hooks/authContext";
import { MatchType } from "../services/api-service";
import {
  getMatchRanking,
  getMatchRankingColor,
} from "../utilities/get-match-ranking";
import UserProfileModal from "./UserProfileModal";
import { Link, useHistory } from "react-router-dom";
import { OrganizationContext } from "../OrganizationContext";

function CommunityPage() {
  const history = useHistory();
  const [selectedMatch, setSelectedMatch] = useState<MatchType>("kindred");
  const { selectedOrg, list } = useContext(OrganizationContext);
  const auth = useAuthContext();
  const api = useApiContext();

  const { isLoading, data, isSuccess } = useQuery({
    enabled: selectedOrg?.isUsersOpen?.BOOL === true && !!auth.userDetails?.["custom:memberId"],
    queryKey: ["SELECTED_ORG_", selectedOrg?.orgcode.S],
    queryFn: () => {
      return api.getMaches({
        id: auth.userDetails?.["custom:memberId"],
        matchType: selectedMatch,
        connectionsLimit: "2000",
        matchesLimit: "500",
        orgcode: selectedOrg?.orgcode.S,
      }, auth.jwt);
    },
  });

  useEffect(() => {
  }, [selectedOrg]);

  return (
    <section className="container">
      <IonAlert
        isOpen={!selectedOrg?.isUsersOpen?.BOOL}
        cssClass={"custom-alert"}
        header="Hey!"
        message="You will receive an email letting you know once this feature is available for you to use!"
        buttons={['Back to persona page']}
        backdropDismiss={false}
        onDidDismiss={() => history.push("/Persona")}
      />
      <div>
        <h1 className="title">Check out your Rivet community below</h1>
        <p>
          The compatibility score associated with each person is based on interests, personality, life stage, and life experience.
        </p>
        <IonNote>
          {selectedMatch === "kindred" ? (
            <>
              A higher percentage score indicates that person approaches life from a similar perspective.
            </>
          ) : (
            <>
              A high number in the <IonText color={"primary"}>Growth</IonText>{" "}
              category indicates your matches approach life from different
              perspectives.
            </>
          )}
        </IonNote>
      </div>
      <div>
        <IonSegment value={selectedMatch} className="ion-margin-top">
          <IonSegmentButton
            value="kindred"
            onClick={() => setSelectedMatch("kindred")}
          >
            <IonLabel>Community</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </div>
      {/* Results Section... */}
      <div>
        {isLoading && (
          Array.from({ length: 4 }).map((value, index) => (
            <IonCard key={index}>
              <IonCardHeader>
                <IonSkeletonText
                  animated
                  style={{ width: "60%", height: "20px" }}
                />
              </IonCardHeader>
              <IonCardContent>
                <IonSkeletonText
                  animated
                  style={{ width: "80%", height: "20px" }}
                />
                <IonSkeletonText
                  animated
                  style={{ width: "100%", height: "20px" }}
                />
                <IonSkeletonText
                  animated
                  style={{ width: "70%", height: "20px" }}
                />
              </IonCardContent>
            </IonCard>
          ))
        )}
        {
          (list.length > 1 && !selectedOrg?.orgcode?.S) && (
            <IonCard>
              <IonCardContent>
                <h2 style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold"
                }}>Hey!</h2>
                <div>
                  <IonText>
                    It looks like you belong to more than one organization, please choose an organization in the Persona screen to see the other users in your organization.
                  </IonText>
                </div>
                <div>
                  <Link to={'/Persona'}>
                    <IonButton className="ion-margin-top">Take me to persona page</IonButton>
                  </Link>
                </div>
              </IonCardContent>
            </IonCard>
          )
        }
        {
          isSuccess && data?.map((item, key) => (
            <IonCard key={key}>
              <IonGrid>
                <IonRow className="ion-align-items-center">
                  <IonCol sizeXs="4" sizeSm="3" sizeMd="3" sizeLg="2" sizeXl="1">
                    {item.profile_picture_url ? <Avatar
                      color={getMatchRankingColor(item.points)}
                      src={item.profile_picture_url.S}
                      round
                    /> : <Avatar
                      color={getMatchRankingColor(item.points)}
                      value={`${item.points}%`}
                      round
                    />}
                  </IonCol>
                  <IonCol>
                    <div className="button-section">
                      <h6
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "bold",
                        }}
                      >
                        {item.user.M.fname.S} {item.user.M.lname.S}
                      </h6>
                      <UserProfileModal {...item} />
                    </div>
                    <p>Active Connections: {item.activeConnections.N}</p>
                    <h6
                      style={{
                        textTransform: "capitalize",
                        color: getMatchRankingColor(item.points),
                      }}
                    >
                      {getMatchRanking(item.points)} {selectedMatch} Match
                    </h6>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
          ))
        }
      </div>
    </section>
  );
}

export default CommunityPage;
