import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonLoading,
  IonNote,
  IonRow,
  IonTitle,
} from "@ionic/react";
import ProfileModalBtn from "./ProfileModalBtn";
import Avatar from "react-avatar";
import { useQuery } from "react-query";
import { useApiContext } from "../hooks/apiContext";
import {
  capitalizeAllFirstWords,
  capitalizeFirstLetter,
} from "../utilities/capitalization";
import {
  ConnectionTypes,
  MemberConnectionTypes,
  QuestionsBaseStepTypes,
  QuestionsBaseTypes,
  QuestionsBaseTypesMap,
  SingleMembersMapTypes,
} from "./interfaces/Connections";
import archetypes from "../data/archetypes.json";
import "../style/UserConnections.css";
import { useAuthContext } from "../hooks/authContext";
import {
  DynamoListStringConnectionTypes,
  SingleMemberTypes,
} from "./interfaces/Dashboard";
import randomInt from "../utilities/random-int";
import { Link } from "react-router-dom";

interface ConnectionErrorTypes {
  message: string;
}

export interface UserConnectionsTypes {
  id: string | undefined;
  name: string | undefined;
  connectionIds: DynamoListStringConnectionTypes;
}

export interface QuestionsToAnswerTypes {
  id: string;
  memberId: string;
  q: string;
  step: number;
}

const UserConnections = ({ id, name, connectionIds }: UserConnectionsTypes) => {
  const api = useApiContext();
  const auth = useAuthContext();

  const { data, isError, isLoading, isFetching, isSuccess } = useQuery<
    MemberConnectionTypes[] | ConnectionErrorTypes,
    Error
  >(
    "connections",
    async () => {
      return await api
        .getUserConnections(id, connectionIds, auth.jwt, false)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const questionExtraction = (cItems: ConnectionTypes) => {
    // grab member2 id and questions and place into object (other member)
    const currentUserObject: SingleMembersMapTypes = Object.values(
      cItems.members.M
    ).find(
      (m: SingleMembersMapTypes) =>
        m.M.id.S === auth.userDetails?.["custom:memberId"]
    );
    const otherMemberId = Object.values(cItems.members.M)
      .map((m: SingleMembersMapTypes) => {
        if (m.M.id.S !== auth.userDetails?.["custom:memberId"]) return m.M.id.S;
      })
      .join("");
    if (!currentUserObject) return [];
    const stepAndSortedQuestions = Object.entries(
      currentUserObject.M.connectionsQuestions.M
    )
      .map(([k, v]: [string, QuestionsBaseTypesMap]) => {
        switch (k) {
          case "connectionMade":
            return { ...v.M, step: 1 };
          case "connectionMetAgain":
            return { ...v.M, step: 2 };
          case "connectionLongTerm":
            return { ...v.M, step: 3 };
          default:
            return { ...v.M, step: 0 };
        }
      })
      .sort((ax, bx) => (ax?.step ?? 0) - (bx?.step ?? 0));

    let escapeRestOfLoop = false;
    return stepAndSortedQuestions
      .map((member: QuestionsBaseStepTypes) => {
        if (escapeRestOfLoop) return undefined;
        if (member.answered.BOOL && !member.willmeetagain.BOOL) {
          escapeRestOfLoop = true;
          return undefined;
        } else if (member.answered.BOOL && member.answer.S === "yes") {
          return undefined;
        }

        switch (member.step) {
          case 1:
            // console.log('past 7 days: ', ((Date.now() - new Date(member.datestamp.S!.toUpperCase()).getTime())/(1000 * 3600 * 24)) >= 7);
            if (
              !member.answered.BOOL &&
              (Date.now() -
                new Date(member.datestamp.S!.toUpperCase()).getTime()) /
                (1000 * 3600 * 24) >=
                14
            ) {
              // past 14 days
              return {
                id: cItems.id.S,
                memberId: otherMemberId,
                q: "connectionMade",
                step: 1,
              };
            }
            return undefined;

          case 2:
            // console.log('past 14 days: ', ((Date.now() - new Date(member.datestamp.S!.toUpperCase()).getTime())/(1000 * 3600 * 24)) >= 14);
            if (
              !member.answered.BOOL &&
              (Date.now() -
                new Date(member.datestamp.S!.toUpperCase()).getTime()) /
                (1000 * 3600 * 24) >=
                30
            ) {
              // past 30 days
              return {
                id: cItems.id.S,
                memberId: otherMemberId,
                q: "connectionMetAgain",
                step: 2,
              };
            }
            return undefined;

          case 3:
            // console.log('past 21 days: ', ((Date.now() - new Date(member.datestamp.S!.toUpperCase()).getTime())/(1000 * 3600 * 24)) >= 21);
            if (
              !member.answered.BOOL &&
              (Date.now() -
                new Date(member.datestamp.S!.toUpperCase()).getTime()) /
                (1000 * 3600 * 60) >=
                21
            ) {
              // past 60 days
              return {
                id: cItems.id.S,
                memberId: otherMemberId,
                q: "connectionLongTerm",
                step: 3,
              };
            }
            return undefined;

          default:
            return undefined;
        }
      })
      .filter((x) => typeof x !== "undefined")
      .sort((ax, bx) => (ax?.step ?? 0) - (bx?.step ?? 0));
  };

  if (isError)
    return (
      <IonNote>There seems to be a problem. Please try again later.</IonNote>
    );
  else if (isLoading || isFetching) return <IonLoading isOpen />;
  else if (
    isSuccess &&
    !(data as ConnectionErrorTypes)?.message &&
    (data as []).length > 0
  )
    return (
      <section className="satoshi">
        <aside>
          <IonTitle className="user-connections-title">Connections</IonTitle>
        </aside>
        <aside style={{ marginTop: "1rem" }}>
          <h4 style={{ marginLeft: "1rem" }}>
            {capitalizeFirstLetter(name ?? "")}'s Current Connections
          </h4>
          <div style={{ marginLeft: "1rem" }}>
            <p>
              Don't know where to start? The Activities page is a great start.
            </p>
            <Link to={"/Activities"}>
              <IonButton>Go to Activities</IonButton>
            </Link>
          </div>
          {(data as MemberConnectionTypes[]).map((u, index) => {
            // run questions extraction function per member2 (figure out if question has not been answered, if it has passed the adequate amount of days)
            const questionsToAnswerArray: QuestionsToAnswerTypes[] | any[] =
              questionExtraction(u.connectionItems);
            // then move the questions that need to be answered into ProfileModalBtn, where ConnectionQuestions.tsx will handle the question asking and submitting
            return (
              <IonCard
                key={`${index}-${randomInt()}`}
                className={`connections ${
                  !u.connectionItems.viewed.BOOL && "shiner"
                }`}
                style={{ borderRadius: "20px" }}
              >
                <IonCardHeader>
                  <IonGrid>
                    <IonRow>
                      <IonCol
                        sizeXl="2"
                        sizeLg="3"
                        sizeMd="4"
                        sizeSm="4"
                        sizeXs="4"
                      >
                        {u.connectionDetails.user.M.profile_picture_url?.S ? (
                          <Avatar
                            style={{
                              fontFamily: "satoshi",
                              fontWeight: "bold",
                            }}
                            src={
                              u.connectionDetails.user.M.profile_picture_url.S
                            }
                            size="80"
                            round={true}
                          />
                        ) : (
                          <Avatar
                            style={{
                              fontFamily: "satoshi",
                              fontWeight: "bold",
                            }}
                            name={`${u.connectionDetails.user.M.fname.S} ${u.connectionDetails.user.M.lname.S}`}
                            size="80"
                            round={true}
                          />
                        )}
                      </IonCol>
                      <IonCol
                        sizeXl="6"
                        sizeLg="5"
                        sizeMd="5"
                        sizeSm="8"
                        sizeXs="7"
                      >
                        <h4 style={{ margin: 0, fontWeight: "bold" }}>
                          {capitalizeAllFirstWords(
                            `${u.connectionDetails.user.M.fname.S?.trim()} ${u.connectionDetails.user.M.lname.S?.trim()}`
                          )}{" "}
                        </h4>
                        <h6 style={{ margin: 0 }}>
                          Connected since{" "}
                          {new Date(
                            (
                              u.connectionItems.matchDateTime.S as string
                            ).toLocaleUpperCase()
                          ).toLocaleDateString()}
                        </h6>
                        <h6 style={{ margin: 0 }}>
                          Connection strength -{" "}
                          {`${u.connectionItems.connectionStrength.N}%`}
                        </h6>
                      </IonCol>
                      <IonCol
                        className="ion-padding-vertical"
                        sizeXl="4"
                        sizeLg="3"
                        sizeMd="2"
                        sizeSm="12"
                        sizeXs="12"
                      >
                        <ProfileModalBtn
                          connection={u}
                          archetypes={archetypes}
                          questionsToAnswer={questionsToAnswerArray}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardHeader>
              </IonCard>
            );
          })}
        </aside>
      </section>
    );
  else
    return (
      <aside>
        {(data as ConnectionErrorTypes)?.message ? (
          <h3 className="user-connections-no-or-fail">
            There seems to be a problem. Please try again later.
          </h3>
        ) : (
          <h3 className="user-connections-no-or-fail">
            There are currently no connections for{" "}
            {capitalizeFirstLetter(name ?? "")}.
          </h3>
        )}
        <div>
          <p>
            Don't know where to start? The Activities page is a great start.
          </p>
          <Link to={"/Activities"}>
            <IonButton>Go to Activities</IonButton>
          </Link>
        </div>
      </aside>
    );
};

export default UserConnections;
